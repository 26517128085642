
import React, { useState } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardTitle, 
  CardBody, 
  CardFooter,
  ButtonGroup,
  Button,
  FormInput,
  ListGroup,
  ListGroupItem,
  FormGroup,
  FormFeedback,
  Breadcrumb, 
  BreadcrumbItem,
  Fade,
  CardHeader,
} from "shards-react";
import { AxiosPOST, API } from "../../utils/BackendAPI";
import PageTitle from "../../components/common/PageTitle";
import { useAuth } from "../../context/auth";
import * as Utils from "../../utils/Utils"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const NewCallThroughNumber = (props) => {
  const NUMBER_STATE = {
    NO_NUMBER: "NO_NUMBER",
    INVALID: "INVALID",
    VALID: "VALID"
  }

  const [formIsValid, setFormIsValid] = useState(true);
  const [callThroughNumber, setCallThroughNumber] = useState();
  const [callThroughNumberFormatted, setCallThroughNumberFormatted] = useState();
  const [numberState, setNumberState] = useState(NUMBER_STATE.NO_NUMBER)
  const { setServiceSuccess } = useAuth()

  const handleChangeCallThroughNumber = e => {
    const numberObj = Utils.whileTypingNumber(e.target.value)
    setNumberState(numberObj.number ? (Utils.isValidPhoneNumber(numberObj.number) ? NUMBER_STATE.VALID : NUMBER_STATE.INVALID) : NUMBER_STATE.NO_NUMBER)
    setCallThroughNumber(numberObj.number)
    setCallThroughNumberFormatted(numberObj.numberFormatted)
    setFormIsValid(numberObj.number ? true : false) 
  }

  function createCallThroughNumber() {
    if (callThroughNumber && Utils.isValidPhoneNumber(callThroughNumber)) {
      setFormIsValid(true)
      AxiosPOST(API.CALL_THROUGH_NUMBER.PATH, [{"number":callThroughNumber}], ({data}) => {
        if (data && data.createdNumbers && data.createdNumbers.filter(numberCreatedElement => (Object.keys(numberCreatedElement).includes(callThroughNumber))).length > 0) {
          setServiceSuccess("The Callthrough Number (" + callThroughNumber + ") was successfully created!")
          setTimeout(() => props.history.goBack(), 200)
        } else if (data && data.notCreatedNumbers && data.notCreatedNumbers.filter(numberNotCreatedElement => (Object.keys(numberNotCreatedElement).includes(callThroughNumber))).length > 0) {
          toast.error(data.notCreatedNumbers.filter(numberNotCreatedElement => (Object.keys(numberNotCreatedElement).includes(callThroughNumber)))[0][callThroughNumber])
        } else {
          toast.error("Unknown error")
        }
      }, (e) => {
        toast.error(Utils.getFriendlyError(e.response.status))
      })
    } else {
      setFormIsValid(false)
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Breadcrumb style={{marginTop: 10}}>
        <BreadcrumbItem>
          <a href="/">Home</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="/callthrough-numbers">Callthrough Numbers</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>New International Number</BreadcrumbItem>
      </Breadcrumb>

      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="New Callthrough Number" subtitle="Dual SIM" className="text-sm-left" />
      </Row>
  
      <Fade>
        <Row>
          <Col lg="6" className="mb-4">
            <Card small className="mb-4">
              <CardHeader><CardTitle>Callthrough Number</CardTitle></CardHeader>
              <CardBody className="p-0 pb-3">
                <Row>
                  {/* Editor */}
                  <Col lg="12" md="12">
                    <ListGroup flush>
                      <ListGroupItem className="px-3">
                        <strong className="text-muted d-block mb-2">
                          Number
                        </strong>
                        <FormGroup>
                          <FormInput maxLength={100} size="lg" className="mb-2" placeholder="+1 347 491 6768" required value={callThroughNumberFormatted || ''} valid={numberState === NUMBER_STATE.VALID} invalid={!formIsValid && !Utils.isValidPhoneNumber(callThroughNumber)} onChange={handleChangeCallThroughNumber} />
                          <FormFeedback valid={formIsValid}>Must be a valid phone number.</FormFeedback>
                        </FormGroup>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </CardBody>
                <CardFooter className="border-top">
                  <ButtonGroup size="sm">
                    <Button
                      size="sm"
                      theme="primary"
                      onClick={() => {
                        createCallThroughNumber()
                      }}
                    >
                      <span className="primary">
                        <i className="material-icons">check</i>
                      </span>{" "}
                    Create
                    </Button>
                    <Button
                      size="sm"
                      theme="white"
                      onClick={() => {
                        props.history.goBack()
                      }}
                    >
                      <span className="white">
                        <i className="material-icons">close</i>
                      </span>{" "}
                    Cancel
                    </Button>
                  </ButtonGroup>
                </CardFooter>
            </Card>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
}

export default NewCallThroughNumber;
