import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
  ButtonGroup,
  Button,
  Breadcrumb, 
  BreadcrumbItem,
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import InternationalDesignationsTableCard from "../../components/table/InternationalDesignationsTableCard";
import { AxiosGET, API } from "../../utils/BackendAPI";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const InternationalDesignations = (props) => {
  const [allInternationalDesignations, setAllInternationalDesignations] = useState();
  const opsColumnName = "Actions"
  const totalNumbersColumnID = "idTotalNumbers"
  const totalCountriesCoverageColumnID = "idTotalCountriesCoverage"
  const validationPrefixColumnID = "idValidationPrefix"
  const internationalNameColumnID = "idName"
  const internationalDescriptionColumnID = "idDescription"
  const InternationalDesignationsColumns = React.useMemo(
    () => [
      {
        Header: 'International Designation',
        columns: [
          {
            Header: 'Name',
            accessor: internationalNameColumnID,
          },
          {
            Header: 'Description',
            accessor: internationalDescriptionColumnID,
          },
          {
            Header: 'Validation prefix',
            accessor: validationPrefixColumnID,
          },
          {
            Header: 'Total of Numbers',
            accessor: totalNumbersColumnID,
          },
          {
            Header: 'Total of Countries Coverage',
            accessor: totalCountriesCoverageColumnID,
          }
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )

  const mainCardActions = (
    <Col lg="5" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm">
        <Button theme="primary" onClick={() => props.history.push("/new-international-designation")}>
          <span className="text-white">
            <i className="material-icons">add</i>
          </span>{" "}
          Add International Designation
        </Button>
      </ButtonGroup>
    </Col>
  )

  useEffect(() => {
    AxiosGET(API.ALL_INTERNATIONAL_DESIGNATIONS.PATH, (data) => setAllInternationalDesignations(data), (e) => toast.error("It was not possible to get all international designations"))
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Breadcrumb style={{marginTop: 10}}>
        <BreadcrumbItem>
          <a href="/">Home</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="/dual-numbers">Dual Numbers</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>International Designations</BreadcrumbItem>
      </Breadcrumb>

      <Row noGutters className="page-header py-4">
        <PageTitle title="International designations" subtitle="Dual SIM" className="text-sm-left mb-3" />
      </Row>
  
      <Fade>
        {
          allInternationalDesignations &&
          <Row>
            <Col lg="12" className="mb-4">
              <InternationalDesignationsTableCard columns={InternationalDesignationsColumns} data={allInternationalDesignations} title="International designations and coverage" opsColumnName="Actions" nav={props.history} validationPrefixColumnID={validationPrefixColumnID} totalNumbersColumnID={totalNumbersColumnID} totalCountriesCoverageColumnID={totalCountriesCoverageColumnID} internationalNameColumnID={internationalNameColumnID} internationalDescriptionColumnID={internationalDescriptionColumnID} mainCardActions={mainCardActions} />
            </Col>
          </Row>
        }
      </Fade>
  
    </Container>
  )
}

export default InternationalDesignations;
