import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
 } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import ActivityReportTableCard from "../../components/table/ActivityReportTableCard";
import { AxiosGET, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ActivityReport = (props) => {
  const [allActivityReport, setAllActivityReport] = useState();
  const myNumberColumnID = "inMyNumber"
  const internationalNumberColumnID = "inInternationalNumber"
  const clientCodeColumnID = "inClientCode"
  const idNameColumnID = "idName"
  const inCreatedDateColumnID = "inCreatedDate"
  const inUpdatedDateColumnID = "inUpdatedDate"
  const ActivityReportColumns = React.useMemo(
    () => [
      {
        Header: 'International number',
        columns: [
          {
            Header: 'My Number',
            accessor: myNumberColumnID,
          },
          {
            Header: 'inMyNumberForSearchPlus',
            accessor: 'inMyNumberForSearchPlus',
          },
          {
            Header: 'inMyNumberForSearch00',
            accessor: 'inMyNumberForSearch00',
          },
          {
            Header: 'inMyNumberForSearchFormatted',
            accessor: 'inMyNumberForSearchFormatted',
          },
          {
            Header: 'International Number',
            accessor: internationalNumberColumnID,
          },
          {
            Header: 'inInternationalNumberForSearchPlus',
            accessor: 'inInternationalNumberForSearchPlus',
          },
          {
            Header: 'inInternationalNumberForSearch00',
            accessor: 'inInternationalNumberForSearch00',
          },
          {
            Header: 'inInternationalNumberForSearchFormatted',
            accessor: 'inInternationalNumberForSearchFormatted',
          },
          {
            Header: 'Client code',
            accessor: clientCodeColumnID,
          },
          {
            Header: 'International Designation',
            accessor: idNameColumnID,
          },
          {
            Header: 'Created at',
            accessor: inCreatedDateColumnID,
          },
          {
            Header: 'Updated at',
            accessor: inUpdatedDateColumnID,
          },
        ],
      },
      {
        Header: 'Requests for last week',
        columns: [
          {
            Header: 'Successful',
            accessor: 'rsLastWeek',
          },
          {
            Header: 'Failed',
            accessor: 'rfLastWeek',
          },
        ],
      },
      {
        Header: 'Requests for last month',
        columns: [
          {
            Header: 'Successful',
            accessor: 'rsLastMonth',
          },
          {
            Header: 'Failed',
            accessor: 'rfLastMonth',
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    fnRenderTable()
  }, []);

  function fnRenderTable() {
    AxiosGET(API.ALL_ACTIVITY_REPORT.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          inMyNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.inMyNumber),
          inMyNumberForSearch00: Utils.getNumberWithPrefix00(d.inMyNumber),
          inMyNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.inMyNumber),
          inInternationalNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.inInternationalNumber),
          inInternationalNumberForSearch00: Utils.getNumberWithPrefix00(d.inInternationalNumber),
          inInternationalNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.inInternationalNumber),
        })

        return d
      })
      setAllActivityReport(data)
    }, 
    (e) => toast.error("It was not possible to load the activity report"))
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Activity report" subtitle="Dual SIM" className="text-sm-left mb-3" />
      </Row>
  
      <Fade>
        {
          allActivityReport &&
          <Row>
            <Col lg="12" className="mb-4">
              <ActivityReportTableCard columns={ActivityReportColumns} data={allActivityReport} title="Activity report" myNumberColumnID={myNumberColumnID} internationalNumberColumnID={internationalNumberColumnID} clientCodeColumnID={clientCodeColumnID} idNameColumnID={idNameColumnID} inCreatedDateColumnID={inCreatedDateColumnID} inUpdatedDateColumnID={inUpdatedDateColumnID} />
            </Col>
          </Row>
        }
      </Fade>
  
    </Container>
  )
}

export default ActivityReport;
