import React, { useState } from "react";
import { 
  Row,
  Col,
  Card,
  CardTitle,
  FormInput,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Modal, 
  ModalBody, 
  ModalHeader,
  ModalFooter,
  Fade,
  Badge,
 } from "shards-react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters, 
  useGlobalFilter
} from 'react-table'
import { AxiosDELETE, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils";
import { useAuth } from "../../context/auth";
import TableNavigation from './TableNavigation'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function GlobalFilterAndActions({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  mainCardActions,
  disableSearch
}) {
  const count = preGlobalFilteredRows.length

  return (
    <Row>
      <Col lg="7" className="mb-2">
        <InputGroup seamless>
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <span className="primary">
                <i className="material-icons">search</i>
              </span>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput 
            maxLength={100}
            value={globalFilter || ''}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} international designations...`}
            disabled={disableSearch}
          />
        </InputGroup>
      </Col>
      { mainCardActions }
    </Row>
  )
}

function InternationalDesignationsTableCard({ columns, data, title, opsColumnName, nav, validationPrefixColumnID, totalNumbersColumnID, totalCountriesCoverageColumnID, internationalNameColumnID, internationalDescriptionColumnID, mainCardActions }) {
  const [rowSelectedToDelete, setRowSelectedToDelete] = useState()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      title,
      initialState: { pageIndex: 0 },
    },
    useFilters, 
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { setServiceSuccess } = useAuth();

  return (
    <>
      <Fade>
        <Card>
          <CardBody>
            <CardTitle>{title}</CardTitle>
            <Modal open={rowSelectedToDelete ? true : false} toggle={() => {}} >
              <ModalHeader>
                <span className="primary">
                  <i className="material-icons">warning</i>
                </span>{" "}Delete International Designation?
              </ModalHeader>
              <ModalBody>
                <>
                <div><strong>Are you sure do you want to delete this international designation?</strong></div>
                <br></br>
                <p>
                  Name: <strong>{rowSelectedToDelete ? rowSelectedToDelete.idName : ""}</strong><br></br>
                  Description: <strong>{rowSelectedToDelete ? rowSelectedToDelete.idDescription : ""}</strong><br></br>
                </p>
                </>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    theme="danger"
                    onClick={() => {
                      AxiosDELETE(API.INTERNATIONAL_DESIGNATION.PATH + rowSelectedToDelete.idID, ({data}) => {
                        setServiceSuccess("The International Designation (" + rowSelectedToDelete.idName + ") was successfully deleted!")
                      }, (e) => {
                        toast.error(Utils.getFriendlyError(e.response.status))
                      })
                    }}
                  >
                    <span className="white">
                      <i className="material-icons">delete</i>
                    </span>{" "}
                  Delete
                  </Button>
                  <Button
                    size="sm"
                    theme="white"
                    onClick={() => {
                      setRowSelectedToDelete()
                    }}
                  >
                    <span className="white">
                      <i className="material-icons">close</i>
                    </span>{" "}
                  Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>

            <div style={{marginBottom: 5}}>
              <GlobalFilterAndActions
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                mainCardActions={mainCardActions}
                disableSearch={canPreviousPage}
              />
            </div>

            <table {...getTableProps()} className="table mb-0 DFETable tableWithActionsColumnIdx4">
              <thead className="bg-light">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="border-0">
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_down</i></span>
                              : <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_up</i></span>
                            : ''}
                        </span>
                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} style={{backgroundColor: Utils.isEven(i) ? "white" : "#FBFBFB"}}>
                      {row.cells.map(cell => {
                        if (cell.column.Header === opsColumnName) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div className="actionsColumn">
                                <ButtonGroup size="sm">
                                  <Button theme="white" onClick={() => nav.push('/edit-international-designation/' + cell.row.original.idID)}>
                                    <span className="text-secondary">
                                      <i className="material-icons">edit</i>
                                    </span>
                                  </Button>
                                  <Button id={""} theme="white" disabled={cell.row.original.idTotalNumbers > 0} onClick={() => {
                                      setRowSelectedToDelete(cell.row.original)
                                    }}>
                                    <span className="text-danger">
                                      <i className="material-icons">delete</i>
                                    </span>
                                  </Button>
                                </ButtonGroup>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === totalNumbersColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div style={{display: "flex", justifyContent: "center"}}>
                                <Badge theme={cell.row.original.idTotalNumbers > 0 ? "info" : "warning"}>{cell.row.original[totalNumbersColumnID]}</Badge>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === totalCountriesCoverageColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div style={{display: "flex", justifyContent: "center"}}>
                                <Badge theme={cell.row.original.idTotalCountriesCoverage > 0 ? "info" : "warning"}>{cell.row.original[totalCountriesCoverageColumnID]}</Badge>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === validationPrefixColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div>
                                {cell.row.original.idValidationPrefix === "NULL" ? "" : cell.row.original.idValidationPrefix}
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === internationalNameColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div>
                                {cell.row.original[internationalNameColumnID] === "NULL" ? "" : cell.row.original[internationalNameColumnID]}
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === internationalDescriptionColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div>
                                {cell.row.original[internationalDescriptionColumnID] === "NULL" ? "" : cell.row.original[internationalDescriptionColumnID]}
                              </div>
                            </td>
                          )
                        }
                        
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <TableNavigation 
              pageIndex={pageIndex} 
              gotoPage={gotoPage} 
              pageSize={pageSize} 
              pageOptions={pageOptions} 
              setPageSize={setPageSize} 
              canPreviousPage={canPreviousPage} 
              canNextPage={canNextPage}
              previousPage={previousPage} 
              nextPage={nextPage} 
              pageCount={pageCount} 
            />
          </CardFooter>
        </Card>
      </Fade>
    </>
  )
}

export default InternationalDesignationsTableCard;
