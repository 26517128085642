import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Fade,
} from "shards-react";
import { API, AxiosPUT } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Unsubscribe = (props) => {
  const [responseReceived, setResponseReceived] = useState(false)
  const [unsubscribed, setUnsubscribed] = useState(false)
  useEffect(() => {
    AxiosPUT(API.UNSUBSCRIBE.PATH, {"unsubscribeToken": props.match.params.unsubscribe_token}, ({data}) => {
      setUnsubscribed(data.unsubscribed)
      setResponseReceived(true)
    }, (e) => {
      toast.error(Utils.getFriendlyError(e.response.status))
      setResponseReceived(true)
    })
  }, []);

  if (!responseReceived) {
    return (
      <Fade>
        <Card large="true" style={{width: 300, marginTop: 100}}>
          <CardBody>
              <div style={{
                justifyContent: "center", 
                display: "flex",
                marginBottom: 20
              }}>
                <span>Unsubscribing, please wait...</span>
              </div>
          </CardBody>
        </Card>
      </Fade>
    )
  }
  
  return (
    <div className="loginCard">
      <Fade>
        <Card large="true" style={{width: 300, marginTop: 100}}>
          <CardBody>
              <div style={{
                justifyContent: "center", 
                display: "flex",
                marginBottom: 20
              }}>
                {
                  unsubscribed &&
                  <span>Unsubscribed</span>
                }
                {
                  !unsubscribed &&
                  <span>Some error occured unsubscribing...</span>
                }
              </div>
          </CardBody>
        </Card>
      </Fade>
    </div>
  )
};

export default Unsubscribe;
