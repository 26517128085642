import React from "react";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

const FloatingButton = ({nav}) => {

    return (
        <Fab
            mainButtonStyles={{
                backgroundColor: "#007bff",
                color: "#ffffff"
            }}
            position={{ bottom: 5, left: 5 }}
            icon={(
                <i className="material-icons">add_box</i>
            )}
            alwaysShowTitle
            // event={event}
            >
            
            <Action
                text="New Dual Number"
                style={actionStyle}
                onClick={() => nav.push("/new-international-number")}
            >
                <i className="material-icons">sim_card</i>
            </Action>
            <Action
                text="New Designation"
                style={actionStyle}
                onClick={() => nav.push("/new-international-designation")}
            >
                <i className="material-icons">label</i>
            </Action>
            <Action
                text="New Callthrough Number"
                style={actionStyle}
                onClick={() => nav.push("/new-call-through-number")}
            >
                <i className="material-icons">call_missed_outgoing</i>
            </Action>
        </Fab>
    )
}

const actionStyle = {
    backgroundColor: '#00b8d8',
    color: "#ffffff"
}

export default FloatingButton;