import React, { useState, useEffect } from "react";
import { 
  Row,
  Col,
  Card,
  CardTitle,
  FormInput,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Modal, 
  ModalBody, 
  ModalHeader,
  ModalFooter,
  Fade,
 } from "shards-react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters, 
  useGlobalFilter
} from 'react-table'
import JSONPretty from 'react-json-pretty';
import * as Utils from "../../utils/Utils";
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-json-pretty/themes/monikai.css';
import TableNavigation from './TableNavigation'

function GlobalFilterAndActions({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  mainCardActions,
  disableSearch
}) {
  const count = preGlobalFilteredRows.length

  return (
    <Row>
      <Col lg="6" className="mb-2">
        <InputGroup seamless>
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <span className="primary">
                <i className="material-icons">search</i>
              </span>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput 
            maxLength={100}
            value={globalFilter || ''}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} log entries...`}
            disabled={disableSearch}
          />
        </InputGroup>
      </Col>
      { mainCardActions }
    </Row>
  )
}

function StageFailureLogTableCard({ mainCardActions, columns, data, title, eventTimeColumnID, mccColumnID, settingsCodeColumnID, myNumberColumnID, stageFailureStepIDColumnID, stageFailureStepNameColumnID, allStageFailureSteps }) {
  const [contentTrackerElement, setContentTrackerElement] = useState()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      title,
      initialState: { pageIndex: 0 },
    },
    useFilters, 
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  useEffect(() => {
    allColumns.filter((col) => (col.id === 'lsfMyNumberForSearchPlus')).map(col => {
      if (col.getToggleHiddenProps().checked) col.toggleHidden()
      return null
    })
    allColumns.filter((col) => (col.id === 'lsfMyNumberForSearch00')).map(col => {
      if (col.getToggleHiddenProps().checked) col.toggleHidden()
      return null
    })
    allColumns.filter((col) => (col.id === 'lsfMyNumberForSearchFormatted')).map(col => {
      if (col.getToggleHiddenProps().checked) col.toggleHidden()
      return null
    })
  }, [columns]);

  const STEP_FAILURE_ELEMENT_TYPES = {
    DONE: "DONE",
    FAIL: "FAIL",
    NOT_DONE: "NOT_DONE"
  }

  function _getStepFailureElementByType(type, el, stepFailureRow) {
    let elDom
    const styleDone = {
      padding: 1,
      textAlign: "center",
      color: "#2B9C40",
      fontSize: 12,
      marginBottom: 2,
    }
    const styleFail = {
      padding: 1,
      textAlign: "center",
      color: "#C32D0E",
      fontSize: 12,
      marginBottom: 2,
      marginRight: 5,
    }
    const styleNotDone = {
      padding: 1,
      textAlign: "center",
      color: "#A4A4A4",
      fontSize: 12,
      marginBottom: 2,
    }
    switch (type) {
      case STEP_FAILURE_ELEMENT_TYPES.DONE: {
        elDom = (
          <div key={el.lssID} style={{display: "flex"}}>
            <div style={styleDone}>
              <i className="material-icons" style={{marginRight: 5}}>assignment_turned_in</i>
              {el.lssName}
            </div>
          </div>
        )
        break
      }
      case STEP_FAILURE_ELEMENT_TYPES.FAIL: {
        elDom = (
          <div key={el.lssID} style={{display: "flex"}}>
            <div style={styleFail}>
              <i className="material-icons" style={{marginRight: 5}}>report</i>
              {el.lssName}{' '}
            </div>
            <span 
              style={{
                padding: 1,
                color: "#007bff",
                fontSize: 12,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setContentTrackerElement(stepFailureRow)}
            >
              <i className="material-icons">find_in_page</i>details
            </span>
          </div>
        )
        break
      }
      case STEP_FAILURE_ELEMENT_TYPES.NOT_DONE: {
        elDom = (
          <div key={el.lssID} style={{display: "flex"}}>
            <div style={styleNotDone}>
              <i className="material-icons" style={{marginRight: 5}}>assignment_late</i>
              {el.lssName}
            </div>
          </div>
        )
        break
      }
      default: {
        elDom = (
          <div key={el.lssID} style={{display: "flex"}}>
            
          </div>
        )
      }
    }

    return elDom
  }

  function _getStepFailureState(stepFailureRow) {
    const stepFailureID = stepFailureRow[stageFailureStepIDColumnID]
    let elements = []
    if (allStageFailureSteps) {
      // allStageFailureSteps.forEach(el => {
      //   if (el) {
      //     elements.push(<Badge key={el.lssID} outline={el.lssID !== stepFailureID} theme={el.lssID < stepFailureID ? "success" : (el.lssID === stepFailureID ? "danger" : "secondary")} style={{marginBottom: 2,}}>{el.lssName}</Badge>)
      //   }
      // })

      allStageFailureSteps.forEach(el => {
        if (el) {
          if (el.lssID === stepFailureID) {
            elements.push(_getStepFailureElementByType(STEP_FAILURE_ELEMENT_TYPES.FAIL, el, stepFailureRow))
          } else {
            elements.push(_getStepFailureElementByType(el.lssID < stepFailureID ? STEP_FAILURE_ELEMENT_TYPES.DONE : STEP_FAILURE_ELEMENT_TYPES.NOT_DONE, el, stepFailureRow))
          }
        }
      })
    }

    return <div style={{
              display: "inline-grid"
            }}>{elements}</div>
  }

  return (
    <>
      <Fade>
        <Card>
          <CardBody>
            <CardTitle>{title}</CardTitle>
            <Modal open={contentTrackerElement ? true : false} toggle={() => {}}>
              <ModalHeader>
                <span className="primary">
                  <i className="material-icons">bug_report</i>
                </span>{" "}Content tracking
              </ModalHeader>
              <ModalBody>
                <div style={{
                  overflowY: "auto",
                  maxHeight: "50vh",
                }}>
                {
                    contentTrackerElement && 
                    <JSONPretty 
                      id="json-pretty" 
                      data={contentTrackerElement.lsfContent} 
                      onJSONPrettyError={e => console.error(e)}
                      mainStyle="line-height:1.3;color:#7F7F7F;background:#FFFFFF;overflow:auto;"
                      keyStyle="color:#4360BB"
                      valueStyle="color:#5074E3"
                      stringStyle="color:#595C64"
                      booleanStyle="color:#5F4997"
                      errorStyle="color:#B21E1E"
                    />
                }
                </div>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    theme="white"
                    onClick={() => {
                      setContentTrackerElement()
                    }}
                  >
                    <span className="white">
                      <i className="material-icons">close</i>
                    </span>{" "}
                  Close
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>

            <div style={{marginBottom: 5}}>
              <GlobalFilterAndActions
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                mainCardActions={mainCardActions}
                disableSearch={canPreviousPage}
              />
            </div>

            <table {...getTableProps()} className="table mb-0 DFETable numberCol">
              <thead className="bg-light">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col"  className={"border-0" + (column.Header === "My Number" ? " numberCol" : "")}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_down</i></span>
                              : <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_up</i></span>
                            : ''}
                        </span>
                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} style={{backgroundColor: Utils.isEven(i) ? "white" : "#FBFBFB"}}>
                      {row.cells.map(cell => {
                        if (cell.column.id === myNumberColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div>{ Utils.getPhoneNumberParsed(cell.row.original[myNumberColumnID]) }</div>
                            </td>
                          )
                        }
                        if (cell.column.id === stageFailureStepNameColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div>{ _getStepFailureState(cell.row.original) }</div>
                            </td>
                          )
                        }
                        if (cell.column.id === eventTimeColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              { cell.row.original[eventTimeColumnID] === "NULL" ? "" : cell.row.original[eventTimeColumnID] }
                            </td>
                          )
                        }
                        if (cell.column.id === mccColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              { cell.row.original[mccColumnID] === "NULL" ? "" : cell.row.original[mccColumnID] }
                            </td>
                          )
                        }
                        if (cell.column.id === settingsCodeColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              { cell.row.original[settingsCodeColumnID] === "NULL" ? "" : cell.row.original[settingsCodeColumnID] }
                            </td>
                          )
                        }
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <TableNavigation 
              pageIndex={pageIndex} 
              gotoPage={gotoPage} 
              pageSize={pageSize} 
              pageOptions={pageOptions} 
              setPageSize={setPageSize} 
              canPreviousPage={canPreviousPage} 
              canNextPage={canNextPage}
              previousPage={previousPage} 
              nextPage={nextPage} 
              pageCount={pageCount} 
            />
          </CardFooter>
        </Card>
      </Fade>
    </>
  )
}

export default StageFailureLogTableCard;
