
import React, { useState, useEffect } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardHeader,
  CardTitle, 
  CardBody, 
  CardFooter,
  ButtonGroup,
  Button,
  FormInput,
  ListGroup,
  ListGroupItem,
  FormGroup,
  FormFeedback,
  Breadcrumb, 
  BreadcrumbItem,
  Fade,
} from "shards-react";
import { AxiosPUT, AxiosGET, API } from "../../utils/BackendAPI";
import Select, { components } from 'react-select';
import PageTitle from "../../components/common/PageTitle";
import { useAuth } from "../../context/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import * as Utils from "../../utils/Utils"

const EditInternationalDesignation = (props) => {
  const [formIsValid, setFormIsValid] = useState(true);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [validationPrefix, setValidationPrefix] = useState();
  const [countriesOptions, setCountriesOptions] = useState([])
  const [selectedCountriesOptions, setSelectedCountriesOptions] = useState([])
  const [originalIntDesignationFromService, setOriginalIntDesignationFromService] = useState();
  const { setServiceSuccess } = useAuth()

  useEffect(() => {
    AxiosGET(API.INTERNATIONAL_DESIGNATION_BY_ID.PATH_PREFIX + props.match.params.id_id + API.INTERNATIONAL_DESIGNATION_BY_ID.PATH_SUFFIX, (data) => {
      const internationalDesignationJSON = data
      setName(internationalDesignationJSON.name)
      setDescription(internationalDesignationJSON.description)
      setValidationPrefix(internationalDesignationJSON.validationPrefix === "NULL" ? "" : internationalDesignationJSON.validationPrefix)
      setOriginalIntDesignationFromService(internationalDesignationJSON)

      AxiosGET(API.ALL_COUNTRIES.PATH, (data) => {
        const options = Array.from(data.map((country) => ({value: country.cID, label: country.cMNC === "NULL" ? country.cLabel : (country.cLabel + " (MNC " + country.cMNC + ")")})))
        setCountriesOptions(options)

        setSelectedCountriesOptions(internationalDesignationJSON.countriesCoverage && internationalDesignationJSON.countriesCoverage.length > 0 ? Array.from(internationalDesignationJSON.countriesCoverage) : [])
      }, (e) => toast.error("It was not possible to get the countries"))

    }, (e) => toast.error("It was not possible to get the international designation"))
  }, []);

  const handleChangeName = e => {
    setName(e.target.value)
    setFormIsValid(e.target.value ? true : false) 
  }

  const handleChangeDescription = e => {
    setDescription(e.target.value)
    setFormIsValid(e.target.value ? true : false) 
  }

  const handleChangeValidationPrefix = e => {
    setValidationPrefix(e.target.value)
    setFormIsValid(e.target.value ? true : false) 
  }
  
  function editInternationalDesignation() {
    if (name && description) {
      setFormIsValid(true)
      const countriesCoverage = getCountriesArray()
      const params = countriesCoverage.length > 0 ? {"name":name,"description":description,"validationPrefix":validationPrefix, "countriesCoverage":countriesCoverage} : {"name":name,"description":description,"validationPrefix":validationPrefix}
      AxiosPUT(API.INTERNATIONAL_DESIGNATION_BY_ID.PATH_PREFIX + props.match.params.id_id + API.INTERNATIONAL_DESIGNATION_BY_ID.PATH_SUFFIX, params, ({data}) => {
        setServiceSuccess("The International Designation (" + name + ") was successfully updated!")
        setTimeout(() => props.history.goBack(), 200)
      }, (e) => {
        toast.error(Utils.getFriendlyError(e.response.status))
      })
    } else {
      setFormIsValid(false)
    }
  }

  function handleChangeCountriesSelection(selectedArr) {
    setSelectedCountriesOptions(selectedArr && selectedArr.length > 0 ? Array.from(selectedArr) : [])
  }

  function getCountriesArray() {
    let countriesArray = []
    if (selectedCountriesOptions && selectedCountriesOptions.length > 0) {
      selectedCountriesOptions.forEach(selectedCountry => {
        countriesArray.push({"countryID": parseInt(selectedCountry.value)})
      })
    }
    return countriesArray
  }

  const header = (
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Edit international Designation" subtitle="Dual SIM" className="text-sm-left" />
    </Row>
  )

  if (!originalIntDesignationFromService) {
    return (
      <Container fluid className="main-content-container px-4">
        {header}
      </Container>
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Breadcrumb style={{marginTop: 10}}>
        <BreadcrumbItem>
          <a href="/">Home</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="/dual-numbers">Dual Numbers</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="/international-designations">International Designations</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Edit international Designation</BreadcrumbItem>
      </Breadcrumb>

      {header}

      <Fade>
        <Row>
          <Col lg="10" className="mb-4">
            <Card small className="mb-4">
              <CardHeader><CardTitle>International Designation</CardTitle></CardHeader>
              <CardBody className="p-0 pb-3">
                <Row>
                  {/* Editor */}
                  <Col lg="12" md="12">
                    <ListGroup flush>
                      <ListGroupItem className="px-3">
                        <strong className="text-muted d-block mb-2">
                          Name
                        </strong>
                        <FormGroup>
                          <FormInput maxLength={50} value={name} size="lg" className="mb-2" placeholder="Short name" required invalid={!name && !formIsValid} onChange={handleChangeName} />
                          <FormFeedback valid={formIsValid}>Name is mandatory.</FormFeedback>
                        </FormGroup>

                        <strong className="text-muted d-block mb-2">
                          Description
                        </strong>
                        <FormGroup>
                          <FormInput maxLength={100} value={description} size="lg" className="mb-2" placeholder="Short description" required invalid={!description && !formIsValid} onChange={handleChangeDescription} />
                          <FormFeedback valid={formIsValid}>Description is mandatory.</FormFeedback>
                        </FormGroup>

                        <strong className="text-muted d-block mb-2">
                          Validation Prefix
                        </strong>
                        <FormGroup>
                          <FormInput maxLength={30} value={validationPrefix} size="lg" className="mb-2" placeholder="Validation prefix" onChange={handleChangeValidationPrefix} />
                        </FormGroup>

                        <strong className="text-muted d-block mb-2">
                          Countries Coverage
                        </strong>
                        <Select
                          value={selectedCountriesOptions}
                          onChange={handleChangeCountriesSelection}
                          options={countriesOptions}
                          isMulti
                          isSearchable
                          isClearable
                          closeMenuOnSelect={false}
                          placeholder={'Select the country(ies)'}
                          styles={{
                            multiValue: base => ({
                              ...base,
                              backgroundColor: '#5a6169',
                              color: "#ffffff"
                            }),
                            multiValueLabel: base => ({
                              ...base,
                              color: "#ffffff"
                            }),
                          }}
                          components={{ MultiValueLabel }}
                        />
                        <span style={{
                              marginTop: 10,
                              display: "flex",
                              justifyContent: "flex-end"
                        }}>{selectedCountriesOptions && selectedCountriesOptions.length > 0 ? (selectedCountriesOptions.length === 1 ? "There is 1 option selected" : "There is " + selectedCountriesOptions.length + " options selected") : "No options selected"}</span>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </CardBody>
                <CardFooter className="border-top">
                  <ButtonGroup size="sm">
                    <Button
                      size="sm"
                      theme="primary"
                      onClick={() => {
                        editInternationalDesignation()
                      }}
                    >
                      <span className="primary">
                        <i className="material-icons">check</i>
                      </span>{" "}
                    Update
                    </Button>
                    <Button
                      size="sm"
                      theme="white"
                      onClick={() => {
                        props.history.goBack()
                      }}
                    >
                      <span className="white">
                        <i className="material-icons">close</i>
                      </span>{" "}
                    Cancel
                    </Button>
                  </ButtonGroup>
                </CardFooter>
            </Card>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
}

const MultiValueLabel = props => {
  return (
    <components.MultiValueLabel {...props} />
  );
};

export default EditInternationalDesignation;
