// Layout Types
import { DefaultLayout, AuthenticationLayout } from "./layouts";

// Route Views
import Logout from "./views/auth/Logout";
import Dashboard from "./views/dashboard/Dashboard";
import StatsReqDualNumbers from './views/dashboard/StatsReqDualNumbers'
import DualNumbers from "./views/internationalNumber/DualNumbers";
import NewInternationalNumber from './views/internationalNumber/NewInternationalNumber';
import EditInternationalNumber from './views/internationalNumber/EditInternationalNumber';
import ImportInternationalNumbersList from './views/internationalNumber/ImportInternationalNumbersList';
import CallThroughNumbers from './views/callthroughNumber/CallThroughNumbers';
import NewCallThroughNumber from './views/callthroughNumber/NewCallThroughNumber';
import EditCallThroughNumber from './views/callthroughNumber/EditCallThroughNumber';
import InternationalDesignations from './views/internationalDesignation/InternationalDesignations';
import NewInternationalDesignation from './views/internationalDesignation/NewInternationalDesignation';
import EditInternationalDesignation from './views/internationalDesignation/EditInternationalDesignation';
import ActivityLogs from './views/activityLog/ActivityLogs'
import ActivityReport from './views/activityReport/ActivityReport'
import UserProfile from './views/auth/UserProfile'
import CountriesCoverage from './views/countriesCoverage/CountriesCoverage';
import StageFailureLog from './views/stageFailureLog/StageFailureLog';

export default [
  //auth
  {
    path: "/logout",
    layout: AuthenticationLayout,
    component: Logout
  },
  {
    path: "/user-profile",
    layout: DefaultLayout,
    component: UserProfile
  },
  //dashboard
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/activity-logs",
    layout: DefaultLayout,
    component: ActivityLogs
  },
  {
    path: "/activity-report",
    layout: DefaultLayout,
    component: ActivityReport
  },
  {
    path: "/stats-req-dual-numbers",
    layout: DefaultLayout,
    component: StatsReqDualNumbers
  },
  //international numbers
  {
    path: "/dual-numbers",
    layout: DefaultLayout,
    component: DualNumbers
  },
  {
    path: "/new-international-number",
    layout: DefaultLayout,
    component: NewInternationalNumber
  },
  {
    path: "/import-international-numbers-list",
    layout: DefaultLayout,
    component: ImportInternationalNumbersList
  },
  {
    path: "/edit-international-number/:my_number_id",
    layout: DefaultLayout,
    component: EditInternationalNumber
  },
  //international designations
  {
    path: "/international-designations",
    layout: DefaultLayout,
    component: InternationalDesignations
  },
  {
    path: "/new-international-designation",
    layout: DefaultLayout,
    component: NewInternationalDesignation
  },
  {
    path: "/edit-international-designation/:id_id",
    layout: DefaultLayout,
    component: EditInternationalDesignation
  },
  //callthrough numbers
  {
    path: "/callthrough-numbers",
    layout: DefaultLayout,
    component: CallThroughNumbers
  },
  {
    path: "/new-call-through-number",
    layout: DefaultLayout,
    component: NewCallThroughNumber
  },
  {
    path: "/edit-call-through-number/:call_through_number_id",
    layout: DefaultLayout,
    component: EditCallThroughNumber
  },
  //countries coverage
  {
    path: "/countries-coverage",
    layout: DefaultLayout,
    component: CountriesCoverage
  },
    //stage failure log
    {
      path: "/stage-failure-log",
      layout: DefaultLayout,
      component: StageFailureLog
    },  
];
