import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Badge,
} from "shards-react";
import { AxiosGET, API } from "../../../utils/BackendAPI";
import * as Utils from "../../../utils/Utils"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const NavbarCallthrough = (props) => {
  const [activeCallthroughNumber, setActiveCallthroughNumber] = useState()
  const [callthroughBtnTooltip, setCallthroughBtnTooltip] = useState(false)

  useEffect(() => {
    AxiosGET(API.ACTIVE_CALL_THROUGH_NUMBER.PATH + "/", (data) => {
      setActiveCallthroughNumber(data.activeCallThroughNumber)
    }, 
    (e) => toast.error("It was not possible to load the active callthrough number"))
  }, []);
  

  return (
    <>
      <Nav id="callthroughNav">
        <NavItem>
          <NavLink active href="/callthrough-numbers">
            <Badge theme="info">
              {"CT# "}{Utils.getPhoneNumberParsed(activeCallthroughNumber)}
            </Badge>
          </NavLink>
        </NavItem>
      </Nav>
      <Tooltip
        placement="bottom"
        open={callthroughBtnTooltip}
        target="#callthroughNav"
        toggle={() => setCallthroughBtnTooltip(!callthroughBtnTooltip)}
      >
        This is the active callthrough number. Press here to change it.
      </Tooltip>
    </>
  )
}

export default NavbarCallthrough;