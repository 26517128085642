import React from "react";
import { 
  Row,
  Col,
  Card,
  CardTitle,
  FormInput,
  CardBody,
  CardFooter,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Fade,
  Badge
 } from "shards-react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters, 
  useGlobalFilter
} from 'react-table'
import * as Utils from "../../utils/Utils";
import TableNavigation from './TableNavigation'

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  disableSearch
}) {
  const count = preGlobalFilteredRows.length

  return (
    <Row>
      <Col lg="6" className="mb-2">
        <InputGroup seamless>
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <span className="primary">
                <i className="material-icons">search</i>
              </span>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput 
            maxLength={100}
            value={globalFilter || ''}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} rows...`}
            disabled={disableSearch}
          />
        </InputGroup>
      </Col>
    </Row>
  )
}

function CountriesCoverageTableCard({ columns, labelColumnID, mccColumnID, mncColumnID, totalColumnID, nameDesignationColumnID, totalDesignationColumnID, data, title }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      title,
      initialState: { pageIndex: 0 },
    },
    useFilters, 
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <>
      <Fade>
        <Card>
          <CardBody>
            <CardTitle>{title}</CardTitle>
            <div style={{marginBottom: 5}}>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                disableSearch={canPreviousPage}
              />
            </div>

            <table {...getTableProps()} className="table mb-0 DFETable">
              <thead className="bg-light">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className={"border-0" + (column.Header === "Total" ? " totalBadgeCol" : "")}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_down</i></span>
                              : <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_up</i></span>
                            : ''}
                        </span>
                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} style={{backgroundColor: Utils.isEven(i) ? "white" : "#FBFBFB"}}>
                      {row.cells.map(cell => {
                        if (cell.column.id === totalColumnID || cell.column.id === totalDesignationColumnID) {
                          var val = 0

                          if (cell.column.id === totalColumnID) {
                            val = cell.row.original[totalColumnID]
                          } else if (cell.column.id === totalDesignationColumnID) {
                            val = cell.row.original[totalDesignationColumnID]
                          }

                          return (
                            <td {...cell.getCellProps()}>
                              <div style={{display: "flex", justifyContent: "center"}}>
                                <Badge theme={val === "0" ? "warning" : "info"}>{val}</Badge>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === labelColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              { cell.row.original[labelColumnID] === "NULL" ? "" : cell.row.original[labelColumnID] }
                            </td>
                          )
                        }
                        if (cell.column.id === mccColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              { cell.row.original[mccColumnID] === "NULL" ? "" : cell.row.original[mccColumnID] }
                            </td>
                          )
                        }
                        if (cell.column.id === mncColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              { cell.row.original[mncColumnID] === "NULL" ? "" : cell.row.original[mncColumnID] }
                            </td>
                          )
                        }
                        if (cell.column.id === nameDesignationColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              { cell.row.original[nameDesignationColumnID] === "NULL" ? "" : cell.row.original[nameDesignationColumnID] }
                            </td>
                          )
                        }

                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <TableNavigation 
              pageIndex={pageIndex} 
              gotoPage={gotoPage} 
              pageSize={pageSize} 
              pageOptions={pageOptions} 
              setPageSize={setPageSize} 
              canPreviousPage={canPreviousPage} 
              canNextPage={canNextPage}
              previousPage={previousPage} 
              nextPage={nextPage} 
              pageCount={pageCount} 
            />
          </CardFooter>
        </Card>
      </Fade>
    </>
  )
}

export default CountriesCoverageTableCard;
