import React, { useState, useEffect } from "react";
import { 
  Row,
  Col,
  Card,
  CardTitle,
  FormInput,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Modal, 
  ModalBody, 
  ModalHeader,
  ModalFooter,
  Fade,
 } from "shards-react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters, 
  useGlobalFilter
} from 'react-table'
import { AxiosDELETE, AxiosPUT, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils";
import { useAuth } from "../../context/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import TableNavigation from './TableNavigation'

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  mainCardActions,
  disableSearch
}) {
  const count = preGlobalFilteredRows.length

  return (
    <Row>
      <Col lg="8" className="mb-2">
        <InputGroup seamless>
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <span className="primary">
                <i className="material-icons">search</i>
              </span>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput 
            maxLength={100}
            value={globalFilter || ''}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} callthrough numbers...`}
            disabled={disableSearch}
          />
        </InputGroup>
      </Col>
      { mainCardActions }
    </Row>
  )
}

function CallThroughNumbersTableCard({ columns, data, title, opsColumnName, numberColumnID, nav, fnRenderTable, mainCardActions }) {
  const [rowSelectedToDelete, setRowSelectedToDelete] = useState()
  const [rowSelectedToEnableActive, setRowSelectedToEnableActive] = useState()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      title,
      initialState: { pageIndex: 0 },
    },
    useFilters, 
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { setServiceSuccess } = useAuth();

  useEffect(() => {
    allColumns.filter((col) => (col.id === 'ctnNumberForSearchPlus')).map(col => {
      if (col.getToggleHiddenProps().checked) col.toggleHidden()
      return null
    })
    allColumns.filter((col) => (col.id === 'ctnNumberForSearch00')).map(col => {
      if (col.getToggleHiddenProps().checked) col.toggleHidden()
      return null
    })
    allColumns.filter((col) => (col.id === 'ctnNumberForSearchFormatted')).map(col => {
      if (col.getToggleHiddenProps().checked) col.toggleHidden()
      return null
    })
  }, [columns]);

  return (
    <>
      <Fade>
        <Card>
          <CardBody>
            <CardTitle>{title}</CardTitle>
            <Modal open={rowSelectedToDelete ? true : false} toggle={() => {}} >
              <ModalHeader>
              <span className="primary">
                  <i className="material-icons">warning</i>
                </span>{" "}Delete Number?
              </ModalHeader>
              <ModalBody>
                <>
                <div><strong>Are you sure do you want to delete this number?</strong></div>
                <br></br>
                <p>
                  Number: <strong>{rowSelectedToDelete ? rowSelectedToDelete.ctnNumber : ""}</strong><br></br>
                  Active state: <strong>{rowSelectedToDelete && rowSelectedToDelete.ctnActive === 1 ? "Active" : "Inactive"}</strong><br></br>
                </p>
                </>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    theme="danger"
                    onClick={() => {
                      AxiosDELETE(API.CALL_THROUGH_NUMBER.PATH + rowSelectedToDelete.ctnID, ({data}) => {
                        setServiceSuccess("The Number (" + rowSelectedToDelete.ctnNumber + ") was successfully deleted!")
                      }, (e) => {
                        toast.error(Utils.getFriendlyError(e.response.status))
                      })
                    }}
                  >
                    <span className="white">
                      <i className="material-icons">delete</i>
                    </span>{" "}
                  Delete
                  </Button>
                  <Button
                    size="sm"
                    theme="white"
                    onClick={() => {
                      setRowSelectedToDelete()
                    }}
                  >
                    <span className="white">
                      <i className="material-icons">close</i>
                    </span>{" "}
                  Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>
            <Modal open={rowSelectedToEnableActive ? true : false} toggle={() => {}} >
              <ModalHeader>
              <span className="primary">
                  <i className="material-icons">check</i>
                </span>{" "}Enable Callthrough Number?
              </ModalHeader>
              <ModalBody>
                <>
                <div><strong>Are you sure do you want to enable this number?</strong></div>
                <br></br>
                <p>
                  Number: <strong>{rowSelectedToEnableActive ? rowSelectedToEnableActive.ctnNumber : ""}</strong><br></br>
                </p>
                </>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    theme="success"
                    onClick={() => {
                      setRowSelectedToEnableActive()
                      AxiosPUT(API.ACTIVE_CALL_THROUGH_NUMBER.PATH, {"callthroughNumberID": rowSelectedToEnableActive.ctnID}, ({data}) => {
                        fnRenderTable(rowSelectedToEnableActive.ctnNumber)
                      }, (e) => {
                        toast.error(Utils.getFriendlyError(e.response.status))
                      })
                    }}
                  >
                    <span className="white">
                      <i className="material-icons">check</i>
                    </span>{" "}
                  Enable
                  </Button>
                  <Button
                    size="sm"
                    theme="white"
                    onClick={() => {
                      setRowSelectedToEnableActive()
                    }}
                  >
                    <span className="white">
                      <i className="material-icons">close</i>
                    </span>{" "}
                  Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>

            <div style={{marginBottom: 5}}>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                mainCardActions={mainCardActions}
                disableSearch={canPreviousPage}
              />
            </div>

            <table {...getTableProps()} className="table mb-0 DFETable tableWithActionsColumnIdx2">
              <thead className="bg-light">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="border-0">
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_down</i></span>
                              : <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_up</i></span>
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} style={{backgroundColor: Utils.isEven(i) ? "white" : "#FBFBFB"}}>
                      {row.cells.map(cell => {
                        const activeNumber = cell.row.original.ctnActive === "1"
                        if (cell.column.Header === opsColumnName) {
                          return (
                            <td {...cell.getCellProps()} className={activeNumber ? "callThroughNumberActive" : ""}>
                              <div className="actionsColumn">
                                <ButtonGroup size="sm">
                                  <Button disabled={activeNumber} theme={activeNumber ? "white" : "success"} onClick={() => {
                                    setRowSelectedToEnableActive(cell.row.original)
                                  }}>
                                    {
                                      !activeNumber &&
                                      <>
                                        <span className="white">
                                          <i className="material-icons">done</i>
                                        </span>{' '}
                                      </>
                                    }
                                    {activeNumber ? "Active Number" : "Enable"}
                                  </Button>

                                  <Button disabled={activeNumber} theme="white" onClick={() => activeNumber ? {} : nav.push('/edit-call-through-number/' + cell.row.original.ctnID)}>
                                    <span className="text-secondary">
                                      <i className="material-icons">edit</i>
                                    </span>
                                  </Button>
                                  <Button disabled={activeNumber} theme="white" onClick={() => activeNumber ? {} : setRowSelectedToDelete(cell.row.original)}>
                                    <span className="text-danger">
                                      <i className="material-icons">delete</i>
                                    </span>
                                  </Button>
                                </ButtonGroup>
                              </div>
                            </td>
                          )
                        } else {
                          return (
                            <td {...cell.getCellProps()} className={activeNumber ? "callThroughNumberActive" : ""}>
                              <div style={{display: "flex", alignItems: "center"}}>
                                { Utils.getPhoneNumberParsed(cell.row.original[numberColumnID]) }
                                {
                                activeNumber &&
                                  <span className="text-success">
                                    <i className="material-icons" style={{fontSize: 25, marginLeft: 5}}>check_circle</i>
                                  </span>
                                }
                              </div>
                            </td>
                          )
                        }
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <TableNavigation 
              pageIndex={pageIndex} 
              gotoPage={gotoPage} 
              pageSize={pageSize} 
              pageOptions={pageOptions} 
              setPageSize={setPageSize} 
              canPreviousPage={canPreviousPage} 
              canNextPage={canNextPage}
              previousPage={previousPage} 
              nextPage={nextPage} 
              pageCount={pageCount} 
            />
          </CardFooter>
        </Card>
      </Fade>
    </>
  )
}

export default CallThroughNumbersTableCard;
