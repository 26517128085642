export default function() {
  return [
    {
      title: "Dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      to: "/dashboard",
    },
    { separator: true },
    {
      title: "Dual Numbers",
      htmlBefore: '<i class="material-icons">sim_card</i>',
      to: "/dual-numbers",
    },
    {
      title: "International Designations",
      htmlBefore: '<i class="material-icons">label</i>',
      to: "/international-designations",
    },
    {
      title: "Callthrough Numbers",
      htmlBefore: '<i class="material-icons">call_missed_outgoing</i>',
      to: "/callthrough-numbers",
    },
    { separator: true },
    {
      title: "System Activity Logs",
      htmlBefore: '<i class="material-icons">dvr</i>',
      to: "/activity-logs",
    },
    {
      title: "Dual Activity Report",
      htmlBefore: '<i class="material-icons">report</i>',
      to: "/activity-report",
    },
    {
      title: "Dual Activity Requests",
      htmlBefore: '<i class="material-icons">list_alt</i>',
      to: "/stats-req-dual-numbers",
    },
    {
      title: "Dual SIM Failure Stage Logs",
      htmlBefore: '<i class="material-icons">low_priority</i>',
      to: "/stage-failure-log",
    },
    { separator: true },
    {
      title: "Countries Coverage",
      htmlBefore: '<i class="material-icons">compass_calibration</i>',
      to: "/countries-coverage",
    },
  ];
}
