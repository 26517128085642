import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
  ButtonGroup,
  Button,
  Tooltip,
 } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import InternationalNumbersTableCard from "../../components/table/InternationalNumbersTableCard";
import { AxiosGET, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const DualNumbers = (props) => {
  const [allInternationalNumbers, setAllInternationalNumbers] = useState();
  const [btnImportCSVFileTooltipState, setBtnImportCSVFileTooltipState] = useState(false)
  const [btnManageIntDesignationsTooltipState, setBtnManageIntDesignationsTooltipState] = useState(false)
  
  const opsColumnName = "Actions"
  const myNumberColumnID = "inMyNumber"
  const internationalNumberColumnID = "inInternationalNumber"
  const clientCodeColumnID = "inClientCode"
  const internationalNameColumnID = "idName"
  const InternationalNumbersColumns = React.useMemo(
    () => [
      {
        Header: 'Numbers',
        columns: [
          {
            Header: 'My Number',
            accessor: myNumberColumnID,
          },
          {
            Header: 'inMyNumberForSearchPlus',
            accessor: 'inMyNumberForSearchPlus',
          },
          {
            Header: 'inMyNumberForSearch00',
            accessor: 'inMyNumberForSearch00',
          },
          {
            Header: 'inMyNumberForSearchFormatted',
            accessor: 'inMyNumberForSearchFormatted',
          },
          {
            Header: 'International Number',
            accessor: internationalNumberColumnID,
          },
          {
            Header: 'inInternationalNumberForSearchPlus',
            accessor: 'inInternationalNumberForSearchPlus',
          },
          {
            Header: 'inInternationalNumberForSearch00',
            accessor: 'inInternationalNumberForSearch00',
          },
          {
            Header: 'inInternationalNumberForSearchFormatted',
            accessor: 'inInternationalNumberForSearchFormatted',
          },
          {
            Header: 'Client Code',
            accessor: clientCodeColumnID,
          },
        ],
      },
      {
        Header: 'International Designation',
        columns: [
          {
            Header: 'Name',
            accessor: internationalNameColumnID,
          },
          // {
          //   Header: 'Description',
          //   accessor: 'idDescription',
          // },
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            accessor: 'inID',
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )

  const mainCardActions = (
    <Col lg="6" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm">
        <Button theme="primary" onClick={() => props.history.push("/new-international-number")}>
          <span className="text-white">
            <i className="material-icons">add</i>
          </span>{" "}
          Add Number
        </Button>
      </ButtonGroup>
      <ButtonGroup size="sm" style={{marginLeft: 10}}>
        <Button id="btnManageIntDesignations" theme="secondary" onClick={() => props.history.push("/international-designations")}>
          <span className="text-white">
            <i className="material-icons">label</i>
          </span>{" "}
          International Designations
        </Button>
      </ButtonGroup>
      <ButtonGroup size="sm" style={{marginLeft: 10}}>
        {/* <Button theme="secondary" onClick={() => {}}>
          <span className="text-white">
            <i className="material-icons">file_upload</i>
          </span>{" "}
          Export
        </Button> */}
        <Button id="btnImportCSVFile" theme="secondary" onClick={() => props.history.push("/import-international-numbers-list")}>
          <span className="text-white">
            <i className="material-icons">file_download</i>
          </span>{" "}
          Import
        </Button>
      </ButtonGroup>
      <Tooltip
        open={btnImportCSVFileTooltipState}
        target="#btnImportCSVFile"
        toggle={() => setBtnImportCSVFileTooltipState(!btnImportCSVFileTooltipState)}
      >
        Import CSV files with serveral numbers.
      </Tooltip>
      <Tooltip
        open={btnManageIntDesignationsTooltipState}
        target="#btnManageIntDesignations"
        toggle={() => setBtnManageIntDesignationsTooltipState(!btnManageIntDesignationsTooltipState)}
      >
        Manage all international designations
      </Tooltip>
    </Col>
  )

  useEffect(() => {
    AxiosGET(API.ALL_INTERNATIONAL_NUMBERS.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          inMyNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.inMyNumber),
          inMyNumberForSearch00: Utils.getNumberWithPrefix00(d.inMyNumber),
          inMyNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.inMyNumber),
          inInternationalNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.inInternationalNumber),
          inInternationalNumberForSearch00: Utils.getNumberWithPrefix00(d.inInternationalNumber),
          inInternationalNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.inInternationalNumber),
        })

        return d
      })
      setAllInternationalNumbers(data)
    }, (error) => toast.error("It was not possible to get all international numbers"))
  }, [props]);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Dual numbers" subtitle="Dual SIM" className="text-sm-left mb-3" />
      </Row>

      <Fade>
        {
          allInternationalNumbers &&
          <Row>
            <Col lg="12" className="mb-4">
              <InternationalNumbersTableCard columns={InternationalNumbersColumns} data={allInternationalNumbers} title="Dual number specification" opsColumnName="Actions" myNumberColumnID={myNumberColumnID} internationalNumberColumnID={internationalNumberColumnID} clientCodeColumnID={clientCodeColumnID} internationalNameColumnID={internationalNameColumnID} nav={props.history} mainCardActions={mainCardActions} />
            </Col>
          </Row>
        }
      </Fade>
    </Container>
  )
}

export default DualNumbers;
