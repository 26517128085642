
import React, { useState, useEffect } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardHeader,
  CardTitle, 
  CardBody, 
  CardFooter,
  ButtonGroup,
  Button,
} from "shards-react";
import { AxiosPOST, AxiosGET, API } from "../../utils/BackendAPI";
import PageTitle from "../../components/common/PageTitle";
import Dropzone from 'react-dropzone';
import * as Utils from "../../utils/Utils";
import { useAuth } from "../../context/auth";
import InternationalNumbersTableCard from "../../components/table/InternationalNumbersTableCard";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ImportInternationalNumbersList = (props) => {
  const [dragOver, setDragOver] = useState(false)
  const [allInternationalDesignations, setAllInternationalDesignations] = useState();
  const [newLinesToVerify, setNewLinesToVerify] = useState(false)
  const [newIntNumbersValidToCreate, setNewIntNumbersValidToCreate] = useState()
  const { setServiceSuccess } = useAuth()
  
  const verifyColumnName = "willCreateVerification"
  const myNumberColumnID = "inMyNumber"
  const internationalNumberColumnID = "inInternationalNumber"
  const clientCodeColumnID = "inClientCode"
  const InternationalNumbersColumns = React.useMemo(
    () => [
      {
        Header: 'Numbers',
        columns: [
          {
            Header: 'My Number',
            accessor: myNumberColumnID,
          },
          {
            Header: 'inMyNumberForSearchPlus',
            accessor: 'inMyNumberForSearchPlus',
          },
          {
            Header: 'inMyNumberForSearch00',
            accessor: 'inMyNumberForSearch00',
          },
          {
            Header: 'inMyNumberForSearchFormatted',
            accessor: 'inMyNumberForSearchFormatted',
          },
          {
            Header: 'International Number',
            accessor: internationalNumberColumnID,
          },
          {
            Header: 'inInternationalNumberForSearchPlus',
            accessor: 'inInternationalNumberForSearchPlus',
          },
          {
            Header: 'inInternationalNumberForSearch00',
            accessor: 'inInternationalNumberForSearch00',
          },
          {
            Header: 'inInternationalNumberForSearchFormatted',
            accessor: 'inInternationalNumberForSearchFormatted',
          },
          {
            Header: 'Client Code',
            accessor: clientCodeColumnID,
          },
        ],
      },
      {
        Header: 'International Designation',
        columns: [
          {
            Header: 'Name',
            accessor: 'idName',
          },
        ],
      },
      {
        Header: 'Verification',
        columns: [
          {
            Header: 'Will be processed?',
            accessor: verifyColumnName,
          },
        ],
      },
    ],
    []
  )

  const mainCardActions = (
    <Col lg="6" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm">
        <Button
          size="sm"
          theme="white"
          onClick={() => {
            setNewLinesToVerify()
          }}
        >
          <span className="white">
            <i className="material-icons">close</i>
          </span>{" "}
        Cancel
        </Button>
        {
          newLinesToVerify &&
            <Button
              size="sm"
              theme="success"
              onClick={() => {
                createInternationalNumbers()
              }}
            >
              <span className="primary">
                <i className="material-icons">check</i>
              </span>{" "}
            Create Numbers
            </Button>
        }
      </ButtonGroup>
    </Col>
  )

  useEffect(() => {
    AxiosGET(API.ALL_INTERNATIONAL_DESIGNATIONS.PATH, (data) => setAllInternationalDesignations(data), (e) => toast.error("It was not possible to get the international designations"))
  }, []);

  function processNewLoadedLines(newLines) {
    var newLinesArr = []
    var verifyNumbersArr = []
    if (newLines && newLines.length > 0) {
      newLines.forEach((lineArr) => {
        if (lineArr && lineArr.length === 4) {
          const internationalDesignation = getInternationalDesignationByID(lineArr[3].split(":")[1])

          newLinesArr.push({
            "inMyNumber": lineArr[0].split(":")[1],
            "inMyNumberForSearchPlus": Utils.getNumberWithPrefixPlus(lineArr[0].split(":")[1]),
            "inMyNumberForSearch00": Utils.getNumberWithPrefix00(lineArr[0].split(":")[1]),
            "inMyNumberForSearchFormatted": Utils.getPhoneNumberParsed(lineArr[0].split(":")[1]),
            "inInternationalNumber": lineArr[1].split(":")[1],
            "inInternationalNumberForSearchPlus": Utils.getNumberWithPrefixPlus(lineArr[1].split(":")[1]),
            "inInternationalNumberForSearch00": Utils.getNumberWithPrefix00(lineArr[1].split(":")[1]),
            "inInternationalNumberForSearchFormatted": Utils.getPhoneNumberParsed(lineArr[1].split(":")[1]),
            "inClientCode": lineArr[2].split(":")[1],
            "idID": internationalDesignation.idID,
            "idName": internationalDesignation.idName,
            "idDescription": internationalDesignation.idDescription
          })
          verifyNumbersArr.push({
            "myNumber":lineArr[0].split(":")[1]
          })
        } else {
          //TODO: show error
        }
      })
    } else {
      //TODO: show error
    }

    AxiosPOST(API.VERIFY_NUMBERS.PATH, verifyNumbersArr, ({data}) => {
      var newIntNumbersValid = []
      // console.log({data})
      newLinesArr.forEach((newLine) => {
        newLine[verifyColumnName] = (data && data.includes(newLine.inMyNumber)) ? false : true
        if (!data.includes(newLine.inMyNumber)) {
          newIntNumbersValid.push({
            "myNumber":newLine.inMyNumber,
            "internationalNumber":newLine.inInternationalNumber,
            "clientCode":newLine.inClientCode,
            "internationalDesignationID":newLine.idID
          })
        }
      })
      // console.log({newLinesArr})
      setNewLinesToVerify(newLinesArr)
      setNewIntNumbersValidToCreate(newIntNumbersValid)
    },
    (error) => { console.log({error})})
  }

  function getInternationalDesignationByID(idID) {
    var BreakException = {};
    var internationalDesignation = {}
    try {
      if (allInternationalDesignations) {
        allInternationalDesignations.forEach((id) => {
          if (id.idID === idID) {
            internationalDesignation = {
              "idID":id.idID,
              "idName":id.idName,
              "idDescription":id.idDescription
            }
            throw BreakException
          }
        })
      }
    } catch (e) {
      if (e !== BreakException) throw e;
    }

    return internationalDesignation
  }

  function createInternationalNumbers() {
    if (newIntNumbersValidToCreate && newIntNumbersValidToCreate.length > 0) {
      AxiosPOST(API.INTERNATIONAL_NUMBER.PATH, newIntNumbersValidToCreate, ({data}) => {
        if (data && data.createdMyNumbers && data.createdMyNumbers.length > 0) {
          setServiceSuccess(data.createdMyNumbers.length + (data.createdMyNumbers.length === 1 ? " My Number was " : " My Numbers were ") + "successfully created!")
          setTimeout(() => props.history.goBack(), 500)
        } else {
          toast.error("Unknown error")
        }
      }, (e) => {
        toast.error(Utils.getFriendlyError(e.response.status))
      })
    } else {
      toast.error("There is no My Numbers available to create")
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle sm="6" title="Import dual numbers" subtitle="Dual SIM" className="text-sm-left" />
      </Row>

      <Row>
        <Col lg="4" className="mb-4">
          <Card small className="mb-4">
            <CardHeader><CardTitle>Drag and drop a CSV file</CardTitle></CardHeader>
            <CardBody className="p-0 pb-3">
              <Row>
                <Col lg="12" md="12" style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                  <Dropzone onDrop={acceptedFiles => {
                    // console.log(acceptedFiles)
                    Utils.getFileAsText(acceptedFiles[0], (e) => {
                      processNewLoadedLines(Utils.getCSVObjFromFile(e.target.result))
                    }, (evt) => {
                      //TODO: show error
                      if(evt.target.error.name === "NotReadableError") {

                      }
                    })

                    setDragOver(false)
                  }}>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div 
                          {...getRootProps()} 
                          onDragEnter={() => setDragOver(true)} 
                          onDragLeave={() => setDragOver(false)} 
                          style={{
                            marginTop: 15,
                            height: 350, 
                            width: 350, 
                            border: newLinesToVerify ? "5px dashed #D6FFCF" : (dragOver ? "10px dashed #D6FFCF" : "10px dashed #ccc") ,
                            backgroundColor: dragOver ? "#B4F4A9" : "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                          className="dropFileZone"
                        >
                          <input {...getInputProps()} />
                          {
                            !dragOver &&
                            <>
                              <div>
                                <i style={{fontSize: 70, color: newLinesToVerify ? "mediumseagreen" : "dimgrey"}} className="material-icons">{newLinesToVerify ? "cloud_done" : "cloud_download"}</i>
                              </div>
                              <div className={newLinesToVerify ? "titleLoaded" : "titleUnload"}>{newLinesToVerify ? "Please verify the numbers to process." : "Drop File Zone"}</div>
                              <div className={newLinesToVerify ? "subtitleLoaded" : "subtitleUnload"}>{newLinesToVerify ? "Drop a file or press here select a file to load again." : "or press here select a file to load."}</div>
                            </>
                          }
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>
              </Row>
            </CardBody>
              <CardFooter className="border-top">
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    theme="white"
                    onClick={() => {
                      props.history.goBack()
                    }}
                  >
                    <span className="white">
                      <i className="material-icons">close</i>
                    </span>{" "}
                  Cancel
                  </Button>
                </ButtonGroup>
              </CardFooter>
          </Card>
        </Col>
        <Col lg="8" className="mb-4">
          {
            newLinesToVerify &&
            <InternationalNumbersTableCard mainCardActions={mainCardActions} columns={InternationalNumbersColumns} myNumberColumnID={myNumberColumnID} internationalNumberColumnID={internationalNumberColumnID} clientCodeColumnID={clientCodeColumnID} data={newLinesToVerify} verifyColumnName={verifyColumnName} title="Numbers to verify" nav={props.history} />
          }
        </Col>
      </Row>
  
    </Container>
  );
}

export default ImportInternationalNumbersList;
