import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from './PrivateRoutes';
import withTracker from "./withTracker";
import { AuthContext } from "./context/auth";
import Cookies from 'js-cookie'
import Error404 from './views/error/Error404'
import * as AppLocalStorage from './utils/AppLocalStorage'
import { decode } from 'jsonwebtoken';
import { ToastContainer, toast } from 'react-toastify';
import FloatingButton from './components/common/FloatingButton'

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./styles/dual-front-end.css";
import 'react-toastify/dist/ReactToastify.min.css';

function App(props) {

  const [authTokens, setAuthTokens] = useState();
  const [serviceSuccess, setServiceSuccess] = useState();
  
  const setTokens = (data) => {
    AppLocalStorage.setLocalStorageFor(AppLocalStorage.LOCAL_STORAGE_KEYS.SESSION_TOKEN, JSON.stringify(data))
    setAuthTokens(data);
  }

  useEffect(() => {
    if (serviceSuccess) {
      toast.success(serviceSuccess);
    }
  }, [serviceSuccess]);

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, setServiceSuccess }}>
      <ToastContainer />
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <Switch>
          {PublicRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
            );
          })}
          {PrivateRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => 
                  authTokens || getSession() ? (
                    <route.layout {...props}>
                      <route.component {...props} />
                      <FloatingButton nav={props.history} />
                    </route.layout>
                  ) : (
                      <Redirect
                          to={{ pathname: "/login", state: { referer: props.location } }}
                      />
                  )
                )}
              />
            );
          })}
          <Route component={Error404} />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

function isCookieExpired(jwtToken) {
  var decoded = decode(jwtToken)

  var now = Math.floor(Date.now() / 1000)

  if (decoded.exp < now) {
    return true
  }

  return false
}

export const getSession = () => {
  const jwtToken = Cookies.get('__session')
  let session = null
  try {
    if (!jwtToken) {
      //continue to login
    } else if (isCookieExpired(jwtToken)) {
      session = null
    } else {
      session = jwtToken
    }
  } catch (error) {
    console.log(error)
  }
  return session
}

export const logOut = () => {
  Cookies.remove('__session')
}

export default App;