import React from "react";
import PropTypes from "prop-types";
import Chart from "../utils/chart";

class ChartCountriesCoverageByDesignation extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this._buildChart(this.props.globalData)
  }

  componentWillReceiveProps(props) {
    this._buildChart(props.globalData)
  }

  _buildChart(globalData) {
    console.log({globalData})
    var chartData = {
      datasets: [
        {
          hoverBorderColor: "#ffffff",
          data: globalData.data,
          backgroundColor: [
            'rgba(93, 173, 226,0.5)',
            'rgba(33, 97, 140,0.5)',
            'rgba(20, 143, 119,0.5)',
            'rgba(115, 198, 182,0.5)',
            'rgba(252, 243, 207,0.7)',
            'rgba(243, 156, 18,0.5)',
            'rgba(175, 96, 26,0.5)',
            'rgba(160, 64, 0,0.5)',
            'rgba(135, 54, 0,0.5)'
          ]
        }
      ],
      labels: globalData.labels
    }
    
    this._renderChart(chartData)

  }

  _renderChart(chartData) {
    const chartConfig = {
      type: "polarArea",
      data: chartData,
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20
            }
          },
          cutoutPercentage: 0,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        },
        ...this.props.chartOptions
      }
    };

    this.setState({
      tasksMap: this.props.tasksMap
    })

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {
    return (
      <canvas
        height="220"
        ref={this.canvasRef}
        className="blog-users-by-device m-auto"
      />
    );
  }
}

ChartCountriesCoverageByDesignation.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

export default ChartCountriesCoverageByDesignation;
