import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
  Card, 
  CardTitle, 
  CardBody, 
  CardHeader,
 } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import CountriesCoverageTableCard from "../../components/table/CountriesCoverageTableCard";
import { AxiosGET, API } from "../../utils/BackendAPI";
import ChartCountriesCoverageByDesignation from '../../charts/ChartCountriesCoverageByDesignation'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const CountriesCoverage = (props) => {
  const [countriesCoverageByCountry, setCountriesCoverageByCountry] = useState();
  const [countriesCoverageByDesignation, setCountriesCoverageByDesignation] = useState();
  const [chartCoverageByDesignationData, setChartCoverageByDesignationData] = useState();
  const labelColumnID = 'cLabel'
  const mccColumnID = 'cMCC'
  const mncColumnID = 'cMNC'
  const totalColumnID = 'cTotalCountriesCoverage'
  const CoverageByCountryColumns = React.useMemo(
    () => [
      {
        Header: 'Total coverage by country',
        columns: [
          {
            Header: 'Country',
            accessor: labelColumnID,
          },
          {
            Header: 'MCC',
            accessor: mccColumnID,
          },
          {
            Header: 'MNC',
            accessor: mncColumnID,
          },
          {
            Header: 'Total',
            accessor: totalColumnID,
          },
        ],
      }
    ],
    []
  )

  const nameDesignationColumnID = 'idName'
  const totalDesignationColumnID = 'idTotalCountriesCoverage'
  const CoverageByDesignationColumns = React.useMemo(
    () => [
      {
        Header: 'Total coverage by designation',
        columns: [
            {
                Header: 'Designation',
                accessor: nameDesignationColumnID,
              },
              {
                Header: 'Total',
                accessor: totalDesignationColumnID,
              },
        ],
      }
    ],
    []
  )

  useEffect(() => {
    AxiosGET(API.COUNTRY_COVERAGE_TOTAL_BY_COUNTRY.PATH, (data) => {
        setCountriesCoverageByCountry(data)
    }, (e) => toast.error("It was not possible to get the coverage by countries"))
    AxiosGET(API.COUNTRY_COVERAGE_TOTAL_BY_DESIGNATION.PATH, (data) => {
        setCountriesCoverageByDesignation(data)
        setChartCoverageByDesignationData(extractCoverageByDesignationData(data))
    }, (e) => toast.error("It was not possible to get the coverage by designation"))
  }, []);

  function extractCoverageByDesignationData(data) {
    var retObj = {data: [], labels: []}
    if (data) {
        data.forEach(item => {
            retObj.data.push(item.idTotalCountriesCoverage)
            retObj.labels.push(item.idName)
        })
    }
    return retObj
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Countries coverage" subtitle="Dual SIM" className="text-sm-left mb-3" />
      </Row>
  
      <Fade>
        {
            chartCoverageByDesignationData &&
            <Row>
                <Col lg="6" className="mb-4">
                {
                    countriesCoverageByDesignation &&
                    <CountriesCoverageTableCard columns={CoverageByDesignationColumns} nameDesignationColumnID={nameDesignationColumnID} totalDesignationColumnID={totalDesignationColumnID} data={countriesCoverageByDesignation} title="Designation coverage" />
                }
                </Col>
                <Col lg="6" className="mb-4">
                    <Card small className="mb-4">
                    <CardHeader><CardTitle>Designation coverage</CardTitle></CardHeader>
                    <CardBody className="p-0 pb-3">
                        <ChartCountriesCoverageByDesignation globalData={chartCoverageByDesignationData} />
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        }
        <Row>
            <Col lg="7" className="mb-4">
              {
                countriesCoverageByCountry &&
                <CountriesCoverageTableCard columns={CoverageByCountryColumns} labelColumnID={labelColumnID} mccColumnID={mccColumnID} mncColumnID={mncColumnID} totalColumnID={totalColumnID} data={countriesCoverageByCountry} title="Country coverage" />
              }
            </Col>
        </Row>
      </Fade>
  
    </Container>
  )
}

export default CountriesCoverage;
