import React from "react";
import PropTypes from "prop-types";
import Chart from "../utils/chart";

class ChartDashboardStageFailureLogs extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this._buildChart(this.props.globalData)
  }

  componentWillReceiveProps(props) {
    this._buildChart(props.globalData)
  }

  _buildChart(globalData) {
    var totals = []
    var labels = []
    Object.values(globalData).forEach((stageFailureLog)=>{
      totals.push(stageFailureLog.sflCount)
      labels.push(stageFailureLog.sflID + ') ' + stageFailureLog.sflName)
    });

    var chartData = {
      datasets: [
        {
          hoverBorderColor: "#ffffff",
          data: totals,
          backgroundColor: [
            "#BB0300",
            "#CC53A2",
            "#702880",
            "#CE4A08"
          ]
        }
      ],
      labels: labels
    }
    
    this._renderChart(chartData)

  }

  _renderChart(chartData) {
    const chartConfig = {
      type: "pie",
      data: chartData,
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20
            }
          },
          cutoutPercentage: 40,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        },
        ...this.props.chartOptions
      }
    };

    this.setState({
      tasksMap: this.props.tasksMap
    })

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {
    return (
      <canvas
        height="220"
        ref={this.canvasRef}
        className="blog-users-by-device m-auto"
      />
    );
  }
}

ChartDashboardStageFailureLogs.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

export default ChartDashboardStageFailureLogs;
