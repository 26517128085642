import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
 } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import StageFailureLogTableCard from "../../components/table/StageFailureLogTableCard";
import { AxiosGET, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const StageFailureLog = (props) => {
  const [allStageFailureLog, setAllStageFailureLog] = useState();
  const [allStageFailureSteps, setAllStageFailureSteps] = useState();

  const myNumberColumnID = "lsfMyNumber"
  const stageFailureStepNameColumnID = "lsfStageFailureStepName"
  const stageFailureStepIDColumnID = "lsfStageFailureStepID"
  const eventTimeColumnID = "lsfEventTime"
  const mccColumnID = "lsfMCC"
  const settingsCodeColumnID = "lsfSettingsCode"
  
  const StageFailureLogColumns = React.useMemo(
    () => [
      {
        Header: 'Stage Failure Log',
        columns: [
          {
            Header: 'My Number',
            accessor: myNumberColumnID,
          },
          {
            Header: 'lsfMyNumberForSearchPlus',
            accessor: 'lsfMyNumberForSearchPlus',
          },
          {
            Header: 'lsfMyNumberForSearch00',
            accessor: 'lsfMyNumberForSearch00',
          },
          {
            Header: 'lsfMyNumberForSearchFormatted',
            accessor: 'lsfMyNumberForSearchFormatted',
          },
          {
            Header: 'Event Time',
            accessor: eventTimeColumnID,
          },
          {
            Header: 'MCC',
            accessor: mccColumnID,
          },
          {
            Header: 'Settings Code',
            accessor: settingsCodeColumnID,
          },
          {
            Header: 'Stage Failure Stage',
            accessor: stageFailureStepNameColumnID,
          },
        ],
      }
    ],
    []
  )

  useEffect(() => {
    AxiosGET(API.ALL_STAGE_FAILURE_LOG.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          lsfMyNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.lsfMyNumber),
          lsfMyNumberForSearch00: Utils.getNumberWithPrefix00(d.lsfMyNumber),
          lsfMyNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.lsfMyNumber),
        })

        return d
      })
      setAllStageFailureLog(data)
    }, (e) => toast.error("It was not possible to get the stage failure logs"))

    AxiosGET(API.ALL_STAGE_FAILURE_STEPS.PATH, (data) => {
      setAllStageFailureSteps(data)
    }, (e) => toast.error("It was not possible to get the stage failure steps"))
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Stage failure log" subtitle="Dual SIM" className="text-sm-left mb-3" />
      </Row>
  
      <Fade>
        {
          allStageFailureLog &&
          <Row>
            <Col lg="12" className="mb-4">
              <StageFailureLogTableCard columns={StageFailureLogColumns} data={allStageFailureLog} title="Dual stage failure log" myNumberColumnID={myNumberColumnID} stageFailureStepIDColumnID={stageFailureStepIDColumnID} stageFailureStepNameColumnID={stageFailureStepNameColumnID} eventTimeColumnID={eventTimeColumnID} mccColumnID={mccColumnID} settingsCodeColumnID={settingsCodeColumnID} allStageFailureSteps={allStageFailureSteps} />
            </Col>
          </Row>
        }
      </Fade>
  
    </Container>
  )
}

export default StageFailureLog
