
import React, { useState, useEffect } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardHeader,
  CardTitle, 
  CardBody, 
  CardFooter,
  ButtonGroup,
  Button,
  FormInput,
  ListGroup,
  ListGroupItem,
  FormGroup,
  FormFeedback,
  Breadcrumb, 
  BreadcrumbItem,
  Fade,
} from "shards-react";
import { AxiosPUT, AxiosGET, API } from "../../utils/BackendAPI";
import PageTitle from "../../components/common/PageTitle";
import { useAuth } from "../../context/auth";
import * as Utils from "../../utils/Utils"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const EditCallThroughNumber = (props) => {
  const NUMBER_STATE = {
    NO_NUMBER: "NO_NUMBER",
    INVALID: "INVALID",
    VALID: "VALID"
  }

  const [formIsValid, setFormIsValid] = useState(true);
  const [callThroughNumber, setCallThroughNumber] = useState();
  const [callThroughNumberFormatted, setCallThroughNumberFormatted] = useState();
  const [originalCallThroughNumberFromService, setOriginalCallThroughNumberFromService] = useState();
  const [numberState, setNumberState] = useState(NUMBER_STATE.NO_NUMBER)
  const { setServiceSuccess } = useAuth()

  useEffect(() => {
    AxiosGET(API.CALL_THROUGH_NUMBER_BY_ID.PATH_PREFIX + props.match.params.call_through_number_id + API.CALL_THROUGH_NUMBER_BY_ID.PATH_SUFFIX, (data) => {
      const callThroughNumberJSON = data
      setCallThroughNumber(callThroughNumberJSON.number)
      const numberObj = Utils.whileTypingNumber(callThroughNumberJSON.number)
      setNumberState(numberObj.number ? (Utils.isValidPhoneNumber(numberObj.number) ? NUMBER_STATE.VALID : NUMBER_STATE.INVALID) : NUMBER_STATE.NO_NUMBER)
      setCallThroughNumberFormatted(numberObj.numberFormatted)
      setOriginalCallThroughNumberFromService(callThroughNumberJSON)
    }, (e) => toast.error("It was not possible to get callthrough number"))
  }, []);

  const handleChangeCallThroughNumber = e => {
    const numberObj = Utils.whileTypingNumber(e.target.value)
    setNumberState(numberObj.number ? (Utils.isValidPhoneNumber(numberObj.number) ? NUMBER_STATE.VALID : NUMBER_STATE.INVALID) : NUMBER_STATE.NO_NUMBER)
    setCallThroughNumber(numberObj.number)
    setCallThroughNumberFormatted(numberObj.numberFormatted)
    setFormIsValid(numberObj.number ? true : false) 
  }
  
  function editCallThroughNumber() {
    if (callThroughNumber && Utils.isValidPhoneNumber(callThroughNumber)) {
      setFormIsValid(true)
      AxiosPUT(API.CALL_THROUGH_NUMBER_BY_ID.PATH_PREFIX + props.match.params.call_through_number_id + API.CALL_THROUGH_NUMBER_BY_ID.PATH_SUFFIX, {"number":callThroughNumber}, ({data}) => {
        setServiceSuccess("The Callthrough Number (" + callThroughNumber + ") was successfully updated!")
        setTimeout(() => props.history.goBack(), 200)
      }, (e) => toast.error(Utils.getFriendlyError(e.response.status)))
    } else {
      setFormIsValid(false)
    }
  }

  const header = (
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Edit Callthrough Number" subtitle="Dual SIM" className="text-sm-left" />
    </Row>
  )

  if (!originalCallThroughNumberFromService) {
    return (
      <Container fluid className="main-content-container px-4">
        {header}
      </Container>
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Breadcrumb style={{marginTop: 10}}>
        <BreadcrumbItem>
          <a href="/">Home</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="/callthrough-numbers">Callthrough Numbers</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Edit Callthrough Number</BreadcrumbItem>
      </Breadcrumb>

      {/* Page Header */}
      {header}

      <Fade>
        <Row>
          <Col lg="6" className="mb-4">
            <Card small className="mb-4">
              <CardHeader><CardTitle> Callthrough Number</CardTitle></CardHeader>
              <CardBody className="p-0 pb-3">
                <Row>
                  {/* Editor */}
                  <Col lg="12" md="12">
                    <ListGroup flush>
                      <ListGroupItem className="px-3">
                          <strong className="text-muted d-block mb-2">
                            Number
                          </strong>
                          <FormGroup>
                            <FormInput maxLength={100} size="lg" className="mb-2" placeholder="+1 347 491 6768" required value={callThroughNumberFormatted || ''} valid={numberState === NUMBER_STATE.VALID} invalid={!formIsValid && !Utils.isValidPhoneNumber(callThroughNumber)} onChange={handleChangeCallThroughNumber} />
                            <FormFeedback valid={formIsValid}>Must be a valid phone number.</FormFeedback>
                          </FormGroup>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </CardBody>
                <CardFooter className="border-top">
                  <ButtonGroup size="sm">
                    <Button
                      size="sm"
                      theme="primary"
                      onClick={() => {
                        editCallThroughNumber()
                      }}
                    >
                      <span className="primary">
                        <i className="material-icons">check</i>
                      </span>{" "}
                    Update
                    </Button>
                    <Button
                      size="sm"
                      theme="white"
                      onClick={() => {
                        props.history.goBack()
                      }}
                    >
                      <span className="white">
                        <i className="material-icons">close</i>
                      </span>{" "}
                    Cancel
                    </Button>
                  </ButtonGroup>
                </CardFooter>
            </Card>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
}

export default EditCallThroughNumber;
