
import React, { useState, useEffect } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardHeader,
  CardTitle, 
  CardBody, 
  CardFooter,
  ButtonGroup,
  Button,
  FormInput,
  ListGroup,
  ListGroupItem,
  InputGroup,
  FormSelect,
  FormGroup,
  FormFeedback,
  Breadcrumb, 
  BreadcrumbItem,
  Fade,
} from "shards-react";
import { AxiosPUT, AxiosGET, API } from "../../utils/BackendAPI";
import PageTitle from "../../components/common/PageTitle";
import { useAuth } from "../../context/auth";
import * as Utils from "../../utils/Utils"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const EditInternationalNumber = (props) => {
  const NUMBER_STATE = {
    NO_NUMBER: "NO_NUMBER",
    INVALID: "INVALID",
    VALID: "VALID"
  }

  const [formIsValid, setFormIsValid] = useState(true);
  const [myNumber, setMyNumber] = useState();
  const [originalIntNumberFromService, setOriginalIntNumberFromService] = useState();
  const [internationalNumber, setInternationalNumber] = useState();
  const [internationalNumberFormatted, setInternationalNumberFormatted] = useState();
  const [internationalNumberState, setInternationalNumberState] = useState(NUMBER_STATE.NO_NUMBER)
  const [clientCode, setClientCode] = useState();
  const [allInternationalDesignations, setAllInternationalDesignations] = useState();
  const [internationalDesignationID, setInternationalDesignationID] = useState(0);
  const [intDesignationValidPrefixMap, setIntDesignationValidPrefixMap] = useState(0);
  const { setServiceSuccess } = useAuth()

  useEffect(() => {
    AxiosGET(API.INTERNATIONAL_NUMBER_BY_ID.PATH_PREFIX + props.match.params.my_number_id + API.INTERNATIONAL_NUMBER_BY_ID.PATH_SUFFIX, (data) => {
      const internationalNumberJSON = data
      setMyNumber(internationalNumberJSON.myNumber)
      setInternationalNumber(internationalNumberJSON.internationalNumber)
      const numberObj = Utils.whileTypingNumber(internationalNumberJSON.internationalNumber)
      setInternationalNumberState(numberObj.number ? (Utils.isValidPhoneNumber(numberObj.number) ? NUMBER_STATE.VALID : NUMBER_STATE.INVALID) : NUMBER_STATE.NO_NUMBER)
      setInternationalNumberFormatted(numberObj.numberFormatted)
      setInternationalDesignationID(internationalNumberJSON.internationalDesignationID)
      setOriginalIntNumberFromService(internationalNumberJSON)
      setClientCode(internationalNumberJSON.clientCode === "NULL" ? "" : internationalNumberJSON.clientCode)
      setFormIsValid(isFormValid()) 
    }, (e) => toast.error("It was not possible to get the international number"))
    
    AxiosGET(API.ALL_INTERNATIONAL_DESIGNATIONS.PATH, (data) => {
      if (data) {
        var newIntDesignationValidPrefixMap = {}
        data.filter((intDes) => (intDes.idValidationPrefix && intDes.idValidationPrefix !== "NULL")).map((intDes) => newIntDesignationValidPrefixMap[intDes.idID] = intDes.idValidationPrefix)
        setIntDesignationValidPrefixMap(newIntDesignationValidPrefixMap)
      }

      setAllInternationalDesignations(data)
      setFormIsValid(isFormValid()) 
    }, (e) => toast.error("It was not possible to get the international designations"))
  }, []);

  const handleChangeInternationalNumber = e => {
    const numberObj = Utils.whileTypingNumber(e.target.value)
    setInternationalNumberState(numberObj.number ? (Utils.isValidPhoneNumber(numberObj.number) ? NUMBER_STATE.VALID : NUMBER_STATE.INVALID) : NUMBER_STATE.NO_NUMBER)
    setInternationalNumber(numberObj.number)
    setInternationalNumberFormatted(numberObj.numberFormatted)
    setFormIsValid(isFormValid()) 
  }

  const handleChangeInternationalDesignation = e => {
    setInternationalDesignationID(e.target.value)
    setFormIsValid(e.target.value !== 0) 
  }

  const handleChangeClientCode = e => {
    setClientCode(e.target.value)
  }
  
  function editInternationalNumber() {
    if (myNumber && Utils.isValidPhoneNumber(myNumber) && internationalNumber && Utils.isValidPhoneNumber(internationalNumber) && parseInt(internationalDesignationID) > 0 && (isToValidateIntNumberPrefixFn() ? isIntNumberWithPrefixValidFn() : true)) {
      setFormIsValid(true)
      AxiosPUT(API.INTERNATIONAL_NUMBER_BY_ID.PATH_PREFIX + props.match.params.my_number_id + API.INTERNATIONAL_NUMBER_BY_ID.PATH_SUFFIX, {"internationalNumber":internationalNumber,"clientCode":clientCode,"internationalDesignationID":parseInt(internationalDesignationID)}, ({data}) => {
        setServiceSuccess("The International Number (" + internationalNumber + ") was successfully updated for My Number (" + myNumber + ")!")
        setTimeout(() => props.history.goBack(), 200)
      }, (e) => {
        toast.error(Utils.getFriendlyError(e.response.status))
      })
    } else {
      setFormIsValid(false)
    }
  }

  function isIntNumberWithPrefixValidFn() {
    if (isToValidateIntNumberPrefixFn()) {
      const valPrefix = intDesignationValidPrefixMap[internationalDesignationID]
      const valPrefixNormalized = Utils.replaceNumberStartingWith00(valPrefix)
      const intNumberNormalized = Utils.replaceNumberStartingWith00(internationalNumber ? internationalNumber : "")
  
      return intNumberNormalized.startsWith(valPrefixNormalized)
    }
    return undefined
  }

  function isToValidateIntNumberPrefixFn() {
    return !!(internationalDesignationID && intDesignationValidPrefixMap && intDesignationValidPrefixMap[internationalDesignationID])
  }

  function isFormValid() {
    return internationalNumber && (isToValidateIntNumberPrefix ? (isIntNumberWithPrefixValid && internationalNumberState === NUMBER_STATE.VALID) : internationalNumberState === NUMBER_STATE.VALID)
  }

  const isIntNumberWithPrefixValid = isIntNumberWithPrefixValidFn()
  const isToValidateIntNumberPrefix = isToValidateIntNumberPrefixFn()

  const intNumberValidRule = isFormValid()
  const intNumberMandatoryNumberValidRule = internationalNumberState === NUMBER_STATE.VALID
  const intNumberPrefixValidRule = intNumberMandatoryNumberValidRule && isIntNumberWithPrefixValid
  const intNumberInvalidRule = !formIsValid && !isIntNumberWithPrefixValid && (isToValidateIntNumberPrefix ? true : !Utils.isValidPhoneNumber(internationalNumber))

  const header = (
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Edit International Number" subtitle="Dual SIM" className="text-sm-left" />
    </Row>
  )

  if (!originalIntNumberFromService) {
    return (
      <Container fluid className="main-content-container px-4">
        {header}
      </Container>
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Breadcrumb style={{marginTop: 10}}>
        <BreadcrumbItem>
          <a href="/">Home</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="/dual-numbers">Dual Numbers</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Edit International Number</BreadcrumbItem>
      </Breadcrumb>

      {header}

      <Fade>
        <Row>
          <Col lg="6" className="mb-4">
            <Card small className="mb-4">
              <CardHeader><CardTitle>International Number</CardTitle></CardHeader>
              <CardBody className="p-0 pb-3">
                <Row>
                  {/* Editor */}
                  <Col lg="12" md="12">
                    <ListGroup flush>
                      <ListGroupItem className="px-3">
                        <strong className="text-muted d-block mb-2">
                          My Number
                        </strong>
                        <FormGroup>
                          <FormInput maxLength={100} disabled={true} value={Utils.getPhoneNumberParsed(myNumber)} size="lg" className="mb-2" placeholder="00351919878800" required invalid={!myNumber && !formIsValid} />
                          <FormFeedback valid={formIsValid}>Must be a valid phone number.</FormFeedback>
                        </FormGroup>

                        <strong className="text-muted d-block mb-2">
                          International Number
                        </strong>
                        <FormGroup>
                          <FormInput maxLength={100} size="lg" className="mb-2" placeholder="+1 347 491 6769" required value={internationalNumberFormatted || ''} valid={!!intNumberValidRule} invalid={!!intNumberInvalidRule} onChange={handleChangeInternationalNumber} />
                          <FormFeedback valid={!!intNumberMandatoryNumberValidRule}>International number must be a valid phone number.</FormFeedback>
                          {
                            !!isToValidateIntNumberPrefix &&
                            <>
                            <FormFeedback valid={!!intNumberPrefixValidRule}>International number must have the prefix "{intDesignationValidPrefixMap[internationalDesignationID]}" to be valid.</FormFeedback>
                            <span className="rowDirection validationPrefix">
                              <span><i className="material-icons">info</i></span>
                              <span>International number must have the prefix "{intDesignationValidPrefixMap[internationalDesignationID]}" to be valid</span>
                            </span>
                            </>
                          }
                        </FormGroup>

                        <strong className="text-muted d-block mb-2">
                          International Designation
                        </strong>
                        <FormGroup>
                          <InputGroup className="mb-3">
                            <FormSelect onChange={handleChangeInternationalDesignation} required invalid={!formIsValid && parseInt(internationalDesignationID) === 0}>
                              <option value={0}>Select a Int. Designation...</option>
                              {
                                allInternationalDesignations && allInternationalDesignations.map((intDesignation, i) => (
                                  <option selected={parseInt(internationalDesignationID) === parseInt(intDesignation.idID)} key={i} value={parseInt(intDesignation.idID) || ''}>{intDesignation.idName}</option>
                                ))
                              }
                            </FormSelect>
                            <FormFeedback valid={formIsValid && parseInt(internationalDesignationID) > 0}>The international Designation is mandatory, please select one.</FormFeedback>
                          </InputGroup>
                        </FormGroup>

                        <strong className="text-muted d-block mb-2">
                          Client Code
                        </strong>
                        <FormGroup>
                          <FormInput maxLength={30} size="lg" className="mb-2" placeholder="DS9009" value={clientCode || ''} onChange={handleChangeClientCode} />
                        </FormGroup>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </CardBody>
                <CardFooter className="border-top">
                  <ButtonGroup size="sm">
                    <Button
                      size="sm"
                      theme="primary"
                      onClick={() => {
                        editInternationalNumber()
                      }}
                    >
                      <span className="primary">
                        <i className="material-icons">check</i>
                      </span>{" "}
                    Update
                    </Button>
                    <Button
                      size="sm"
                      theme="white"
                      onClick={() => {
                        props.history.goBack()
                      }}
                    >
                      <span className="white">
                        <i className="material-icons">close</i>
                      </span>{" "}
                    Cancel
                    </Button>
                  </ButtonGroup>
                </CardFooter>
            </Card>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
}

export default EditInternationalNumber;
