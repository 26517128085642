import React, { useState, useEffect } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardTitle, 
  CardBody, 
  CardFooter,
  Nav, 
  NavItem,
  NavLink,
  Badge,
  Fade,
  CardHeader,
} from "shards-react";
import { AxiosGET, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils"
import PageTitle from "../../components/common/PageTitle";
import ChartDashboardDesignations from '../../charts/ChartDashboardDesignations'
import ChartDashboardStageFailureLogs from '../../charts/ChartDashboardStageFailureLogs'
import ChartDashboardRequestsLastMonth from '../../charts/ChartDashboardRequestsLastMonth'
import SmallStats from '../../charts/SmallStats'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Dashboard = () => {
  const [dashboardStats, setDashboardStats] = useState()
  const [smallDashboardStats, setDashboardSmallStats] = useState()
  
  useEffect(() => {
    AxiosGET(API.DASHBOARD_STATS.PATH, (data) => setDashboardStats(data), (error) => toast.error("It was not possible to get the dashboard stats"))
    AxiosGET(API.DASHBOARD_SMALL_STATS.PATH, (data) => {
      setDashboardSmallStats(extractSmallStats(data))
    }, (error) => toast.error("It was not possible to get the dashboard stats"))
  }, []);

  function extractSmallStats(data) {
    //Dual numbers success requests
    const reqSuccessLast6Months = data.reqSuccessLast6Months
    const totalSuccess = reqSuccessLast6Months.totalBetweenNowAndPlusFiveMonth
    const datasetSuccessData = [
      Utils.getPercentage(reqSuccessLast6Months.totalBetweenPlusFourMonthAndPlusFiveMonth, totalSuccess),
      Utils.getPercentage(reqSuccessLast6Months.totalBetweenPlusThreeMonthAndPlusFourMonth, totalSuccess),
      Utils.getPercentage(reqSuccessLast6Months.totalBetweenPlusTwoMonthAndPlusThreeMonth, totalSuccess),
      Utils.getPercentage(reqSuccessLast6Months.totalBetweenPlusOneMonthAndPlusTwoMonth, totalSuccess),
      Utils.getPercentage(reqSuccessLast6Months.totalBetweenLastMonthAndPlusOneMonth, totalSuccess),
      Utils.getPercentage(reqSuccessLast6Months.totalBetweenNowAndLastMonth, totalSuccess),
    ]
    const reqSuccessLast6MonthPercentage = Utils.getPercentage(reqSuccessLast6Months.totalBetweenNowAndLastMonth, reqSuccessLast6Months.totalBetweenNowAndLastMonth + reqSuccessLast6Months.totalBetweenLastMonthAndPlusOneMonth)
    const reqSuccessLast6MonthIncrease = reqSuccessLast6Months.totalBetweenNowAndLastMonth >= reqSuccessLast6Months.totalBetweenLastMonthAndPlusOneMonth

    //Dual numbers fail requests
    const reqFailLast6Months = data.reqFailLast6Months
    const totalFail = reqFailLast6Months.totalBetweenNowAndPlusFiveMonth
    const datasetFailData = [
      Utils.getPercentage(reqFailLast6Months.totalBetweenPlusFourMonthAndPlusFiveMonth, totalFail),
      Utils.getPercentage(reqFailLast6Months.totalBetweenPlusThreeMonthAndPlusFourMonth, totalFail),
      Utils.getPercentage(reqFailLast6Months.totalBetweenPlusTwoMonthAndPlusThreeMonth, totalFail),
      Utils.getPercentage(reqFailLast6Months.totalBetweenPlusOneMonthAndPlusTwoMonth, totalFail),
      Utils.getPercentage(reqFailLast6Months.totalBetweenLastMonthAndPlusOneMonth, totalFail),
      Utils.getPercentage(reqFailLast6Months.totalBetweenNowAndLastMonth, totalFail),
    ]
    const reqFailLast6MonthPercentage = Utils.getPercentage(reqFailLast6Months.totalBetweenNowAndLastMonth, reqFailLast6Months.totalBetweenNowAndLastMonth + reqFailLast6Months.totalBetweenLastMonthAndPlusOneMonth)
    const reqFailLast6MonthIncrease = reqFailLast6Months.totalBetweenNowAndLastMonth >= reqFailLast6Months.totalBetweenLastMonthAndPlusOneMonth

    //Activity logs failed fail requests
    const reqActivityFailLast6Months = data.reqActivityFailLast6Months
    const totalActivityFail = reqActivityFailLast6Months.totalBetweenNowAndPlusFiveMonth
    const datasetActivityFailData = [
      Utils.getPercentage(reqActivityFailLast6Months.totalBetweenPlusFourMonthAndPlusFiveMonth, totalActivityFail),
      Utils.getPercentage(reqActivityFailLast6Months.totalBetweenPlusThreeMonthAndPlusFourMonth, totalActivityFail),
      Utils.getPercentage(reqActivityFailLast6Months.totalBetweenPlusTwoMonthAndPlusThreeMonth, totalActivityFail),
      Utils.getPercentage(reqActivityFailLast6Months.totalBetweenPlusOneMonthAndPlusTwoMonth, totalActivityFail),
      Utils.getPercentage(reqActivityFailLast6Months.totalBetweenLastMonthAndPlusOneMonth, totalActivityFail),
      Utils.getPercentage(reqActivityFailLast6Months.totalBetweenNowAndLastMonth, totalActivityFail),
    ]
    const reqActivityFailLast6MonthPercentage = Utils.getPercentage(reqActivityFailLast6Months.totalBetweenNowAndLastMonth, reqActivityFailLast6Months.totalBetweenNowAndLastMonth + reqActivityFailLast6Months.totalBetweenLastMonthAndPlusOneMonth)
    const reqActivityFailLast6MonthIncrease = reqActivityFailLast6Months.totalBetweenNowAndLastMonth >= reqActivityFailLast6Months.totalBetweenLastMonthAndPlusOneMonth

    //WBB Dual numbers success requests
    const reqWBBSuccessLast6Months = data.reqWBBSuccessLast6Months
    const totalWBBSuccess = reqWBBSuccessLast6Months.totalBetweenNowAndPlusFiveMonth
    const datasetWBBSuccessData = [
      Utils.getPercentage(reqWBBSuccessLast6Months.totalBetweenPlusFourMonthAndPlusFiveMonth, totalWBBSuccess),
      Utils.getPercentage(reqWBBSuccessLast6Months.totalBetweenPlusThreeMonthAndPlusFourMonth, totalWBBSuccess),
      Utils.getPercentage(reqWBBSuccessLast6Months.totalBetweenPlusTwoMonthAndPlusThreeMonth, totalWBBSuccess),
      Utils.getPercentage(reqWBBSuccessLast6Months.totalBetweenPlusOneMonthAndPlusTwoMonth, totalWBBSuccess),
      Utils.getPercentage(reqWBBSuccessLast6Months.totalBetweenLastMonthAndPlusOneMonth, totalWBBSuccess),
      Utils.getPercentage(reqWBBSuccessLast6Months.totalBetweenNowAndLastMonth, totalWBBSuccess),
    ]
    const reqWBBSuccessLast6MonthPercentage = Utils.getPercentage(reqWBBSuccessLast6Months.totalBetweenNowAndLastMonth, reqWBBSuccessLast6Months.totalBetweenNowAndLastMonth + reqWBBSuccessLast6Months.totalBetweenLastMonthAndPlusOneMonth)
    const reqWBBSuccessLast6MonthIncrease = reqWBBSuccessLast6Months.totalBetweenNowAndLastMonth >= reqWBBSuccessLast6Months.totalBetweenLastMonthAndPlusOneMonth

    //EE Dual numbers success requests
    const reqEESuccessLast6Months = data.reqEESuccessLast6Months
    const totalEESuccess = reqEESuccessLast6Months.totalBetweenNowAndPlusFiveMonth
    const datasetEESuccessData = [
      Utils.getPercentage(reqEESuccessLast6Months.totalBetweenPlusFourMonthAndPlusFiveMonth, totalEESuccess),
      Utils.getPercentage(reqEESuccessLast6Months.totalBetweenPlusThreeMonthAndPlusFourMonth, totalEESuccess),
      Utils.getPercentage(reqEESuccessLast6Months.totalBetweenPlusTwoMonthAndPlusThreeMonth, totalEESuccess),
      Utils.getPercentage(reqEESuccessLast6Months.totalBetweenPlusOneMonthAndPlusTwoMonth, totalEESuccess),
      Utils.getPercentage(reqEESuccessLast6Months.totalBetweenLastMonthAndPlusOneMonth, totalEESuccess),
      Utils.getPercentage(reqEESuccessLast6Months.totalBetweenNowAndLastMonth, totalEESuccess),
    ]
    const reqEESuccessLast6MonthPercentage = Utils.getPercentage(reqEESuccessLast6Months.totalBetweenNowAndLastMonth, reqEESuccessLast6Months.totalBetweenNowAndLastMonth + reqEESuccessLast6Months.totalBetweenLastMonthAndPlusOneMonth)
    const reqEESuccessLast6MonthIncrease = reqEESuccessLast6Months.totalBetweenNowAndLastMonth >= reqEESuccessLast6Months.totalBetweenLastMonthAndPlusOneMonth

    //EE Dual numbers success requests
    const newUsersRequestsLast6Months = data.newUsersRequestsLast6Months
    const totalNewUsers = newUsersRequestsLast6Months.totalBetweenNowAndPlusFiveMonth
    const datasetNewUsersData = [
      Utils.getPercentage(newUsersRequestsLast6Months.totalBetweenPlusFourMonthAndPlusFiveMonth, totalNewUsers),
      Utils.getPercentage(newUsersRequestsLast6Months.totalBetweenPlusThreeMonthAndPlusFourMonth, totalNewUsers),
      Utils.getPercentage(newUsersRequestsLast6Months.totalBetweenPlusTwoMonthAndPlusThreeMonth, totalNewUsers),
      Utils.getPercentage(newUsersRequestsLast6Months.totalBetweenPlusOneMonthAndPlusTwoMonth, totalNewUsers),
      Utils.getPercentage(newUsersRequestsLast6Months.totalBetweenLastMonthAndPlusOneMonth, totalNewUsers),
      Utils.getPercentage(newUsersRequestsLast6Months.totalBetweenNowAndLastMonth, totalNewUsers),
    ]
    const newUsersLast6MonthPercentage = Utils.getPercentage(newUsersRequestsLast6Months.totalBetweenNowAndLastMonth, newUsersRequestsLast6Months.totalBetweenNowAndLastMonth + newUsersRequestsLast6Months.totalBetweenLastMonthAndPlusOneMonth)
    const newUsersLast6MonthIncrease = newUsersRequestsLast6Months.totalBetweenNowAndLastMonth >= newUsersRequestsLast6Months.totalBetweenLastMonthAndPlusOneMonth

    return {
      smallStats: [
        {
          label: "Dual Success Requests",
          value: totalSuccess,
          percentage: reqSuccessLast6MonthPercentage + "%",
          increase: reqSuccessLast6MonthIncrease,
          decrease: !reqSuccessLast6MonthIncrease,
          chartLabels: [null, null, null, null, null, null],
          attrs: { md: "6", sm: "6" },
          toolTip: {wrapperID: "chartCardIDDualSuccess", msg: "Evolution of successful international number requests in the last 6 months. There has been a "+reqSuccessLast6MonthPercentage+"% "+(reqSuccessLast6MonthIncrease ? "increase" : "decrease")+" since the last 30 days."},
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(23,198,113,0.1)",
              borderColor: "rgb(23,198,113)",
              data: datasetSuccessData
            }
          ]
        },
        {
          label: "Dual Fail Requests",
          value: totalFail,
          percentage: reqFailLast6MonthPercentage + "%",
          increase: reqFailLast6MonthIncrease,
          decrease: !reqFailLast6MonthIncrease,
          invertSuccessWay: true,
          chartLabels: [null, null, null, null, null, null],
          attrs: { md: "6", sm: "6" },
          toolTip: {wrapperID: "chartCardIDDualFail", msg: "Evolution of international number requests that have not been successful in the last 6 months. There has been a "+reqFailLast6MonthPercentage+"% "+(reqFailLast6MonthIncrease ? "increase" : "decrease")+" since the last 30 days."},
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(255,65,105,0.1)",
              borderColor: "rgb(255,65,105)",
              data: datasetFailData
            }
          ]
        },
        {
          label: "Activity Fail Events",
          value: totalActivityFail,
          percentage: reqActivityFailLast6MonthPercentage + "%",
          increase: reqActivityFailLast6MonthIncrease,
          decrease: !reqActivityFailLast6MonthIncrease,
          invertSuccessWay: true,
          chartLabels: [null, null, null, null, null, null],
          attrs: { md: "4", sm: "6" },
          toolTip: {wrapperID: "chartCardIDActivitySuccess", msg: "Evolution of system activity that has not been successful in the last 6 months. There has been a "+reqActivityFailLast6MonthPercentage+"% "+(reqActivityFailLast6MonthIncrease ? "increase" : "decrease")+" since the last 30 days."},
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(255,180,0,0.1)",
              borderColor: "rgb(255,180,0)",
              data: datasetActivityFailData
            }
          ]
        },
        {
          label: "WBB Success Requests",
          value: totalWBBSuccess,
          percentage: reqWBBSuccessLast6MonthPercentage + "%",
          increase: reqWBBSuccessLast6MonthIncrease,
          decrease: !reqWBBSuccessLast6MonthIncrease,
          chartLabels: [null, null, null, null, null, null],
          attrs: { md: "6", sm: "6" },
          toolTip: {wrapperID: "chartCardIDWBBSuccess", msg: "Evolution of successful international number requests in the last 6 months for WBB users. There has been a "+reqWBBSuccessLast6MonthPercentage+"% "+(reqWBBSuccessLast6MonthIncrease ? "increase" : "decrease")+" since the last 30 days."},
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgb(0,123,255,0.1)",
              borderColor: "rgb(0,123,255)",
              data: datasetWBBSuccessData
            }
          ]
        },
        {
          label: "EE Success Requests",
          value: totalEESuccess,
          percentage: reqEESuccessLast6MonthPercentage + "%",
          increase: reqEESuccessLast6MonthIncrease,
          decrease: !reqEESuccessLast6MonthIncrease,
          chartLabels: [null, null, null, null, null, null],
          attrs: { md: "6", sm: "6" },
          toolTip: {wrapperID: "chartCardIDEESuccess", msg: "Evolution of successful international number requests in the last 6 months for EE users. There has been a "+reqEESuccessLast6MonthPercentage+"% "+(reqEESuccessLast6MonthIncrease ? "increase" : "decrease")+" since the last 30 days."},
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgb(0,123,255,0.1)",
              borderColor: "rgb(0,123,255)",
              data: datasetEESuccessData
            }
          ]
        },
        {
          label: "New Users",
          value: totalNewUsers,
          percentage: newUsersLast6MonthPercentage + "%",
          increase: newUsersLast6MonthIncrease,
          decrease: !newUsersLast6MonthIncrease,
          chartLabels: [null, null, null, null, null, null],
          attrs: { md: "6", sm: "6" },
          toolTip: {wrapperID: "chartCardIDNewUsers", msg: "Evolution of new users regarding the last 30 days in the last 6 months. There has been a "+newUsersLast6MonthPercentage+"% "+(newUsersLast6MonthIncrease ? "increase" : "decrease")+" since the last 30 days."},
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(23,198,113,0.1)",
              borderColor: "rgb(23,198,113)",
              data: datasetNewUsersData
            }
          ]
        }
      ]
    }
  }

  const header = (
    <Row noGutters className="page-header py-4">
      <PageTitle title="Dashboard" subtitle="Dual SIM" className="text-sm-left mb-3" />
    </Row>
  )

  if (!dashboardStats) {
    return (
      <Container fluid className="main-content-container px-4">
        {header}
      </Container>
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      {header}
  
      <Fade>
        <Row>
          {smallDashboardStats && smallDashboardStats.smallStats.map((stats, idx) => {
            return (
              <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                <SmallStats
                  id={`small-stats-${idx}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  value={stats.value}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                  invertSuccessWay={stats.invertSuccessWay}
                  toolTip={stats.toolTip}
                />
              </Col>
            )
          })}
        </Row>
        <Row>
          <Col lg="5" className="mb-4">
            <Card small className="mb-4">
              <CardHeader><CardTitle>Requests for dual number last month</CardTitle></CardHeader>
              <CardBody className="p-0 pb-3">
                <ChartDashboardRequestsLastMonth globalData={dashboardStats.internationalNumbers} />
              </CardBody>
              <CardFooter className="border-top">
                <Nav>
                  <NavItem>
                    <NavLink active href="/stats-req-dual-numbers">
                      View All &rarr;
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" className="mb-4">
            <Card small className="mb-4">
              <CardHeader><CardTitle>Dual failure by stage</CardTitle></CardHeader>
              <CardBody className="p-0 pb-3">
                <ChartDashboardStageFailureLogs globalData={dashboardStats.stageFailureLog} />
              </CardBody>
              <CardFooter className="border-top">
                <Nav>
                  <NavItem>
                    <NavLink active href="/stage-failure-log">
                      View All Logs &rarr;
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" className="mb-4">
            <Card small className="mb-4">
              <CardHeader><CardTitle>Dual numbers by designation</CardTitle></CardHeader>
              <CardBody className="p-0 pb-3">
                <table style={{margin: 10}}>
                  <tbody>
                    <tr>
                      <td>
                        <strong><Badge theme="info">Total</Badge></strong>
                      </td>
                      <td>
                        <Badge theme="info">{dashboardStats.internationalNumbers.total}</Badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ChartDashboardDesignations globalData={dashboardStats.internationalNumbers} />
              </CardBody>
              <CardFooter className="border-top">
                <Nav>
                  <NavItem>
                    <NavLink active href="/dual-numbers">
                      View All Numbers &rarr;
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink active href="/international-designations">
                      View All Designations &rarr;
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Fade>
    </Container>
  )
}

export default Dashboard;
