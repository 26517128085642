import axios from "axios";
import * as Utils from './Utils'
import * as AppLocalStorage from './AppLocalStorage'
import JSONData from "../apiServerConfig.json"

export const SERVER = {
    PROTOCOL: JSONData.ENDPOINT.PROTOCOL,
    DOMAIN: JSONData.ENDPOINT.DOMAIN,
    PORT: JSONData.ENDPOINT.PORT
}

// export const SERVER = {
//     PROTOCOL: "http://",
//     DOMAIN: "localhost",
//     PORT: "80"
// }

export const API = {
    //auth
    BASE_KEY: "27DO3IOJGFCQ45MLINFY7KIXYGIEYHWZ5RT7755TZBZGFN3RQRZHQ66WLWBWJNRD",
    LOGIN: {
        PATH: "/auth/login/",
    },
    USER_PROFILE: {
        PATH: "/auth/user-profile/",
    },
    UNSUBSCRIBE: {
        PATH: "/auth/unsubscribe/",
    },
    //dashboard
    DASHBOARD_STATS: {
        PATH: "/service/dashboard-stats",
    },
    DASHBOARD_SMALL_STATS: {
        PATH: "/service/dashboard-small-stats",
    },
    ALL_ACTIVITY_LOGS: {
        PATH: "/service/activity-logs",
    },
    ALL_ACTIVITY_REPORT: {
        PATH: "/service/activity-report",
    },
    STATS_REQ_SUCCESS_DUAL_NUMBERS: {
        PATH: "/service/stats-success-req-dual",
    },
    STATS_REQ_FAIL_DUAL_NUMBERS: {
        PATH: "/service/stats-fail-req-dual",
    },
    STATS_REQ_SUCCESS_NEW_USERS: {
        PATH: "/service/stats-success-req-dual-new-users",
    },
    //international numbers
    ALL_INTERNATIONAL_NUMBERS: {
        PATH: "/service/international-number",
    },
    INTERNATIONAL_NUMBER: {
        PATH: "/service/international-number/",
    },
    INTERNATIONAL_NUMBER_BY_ID: {
        PATH_PREFIX: "/service/international-number/",
        PATH_SUFFIX: "/byID",
    },
    VERIFY_NUMBERS: {
        PATH: "/service/verify-numbers/",
    },
    //callthrough numbers
    ALL_CALL_THROUGH_NUMBERS: {
        PATH: "/service/callthrough-number",
    },
    ACTIVE_CALL_THROUGH_NUMBER: {
        PATH: "/service/active-callthrough-number",
    },
    CALL_THROUGH_NUMBER: {
        PATH: "/service/callthrough-number/",
    },
    CALL_THROUGH_NUMBER_BY_ID: {
        PATH_PREFIX: "/service/callthrough-number/",
        PATH_SUFFIX: "/byID",
    },
    //international designations
    ALL_INTERNATIONAL_DESIGNATIONS: {
        PATH: "/service/international-designation",
    },
    INTERNATIONAL_DESIGNATION: {
        PATH: "/service/international-designation/",
    },
    INTERNATIONAL_DESIGNATION_BY_ID: {
        PATH_PREFIX: "/service/international-designation/",
        PATH_SUFFIX: "/byID",
    },
    //country
    ALL_COUNTRIES: {
        PATH: "/service/country",
    },
    COUNTRY_COVERAGE_TOTAL_BY_COUNTRY: {
        PATH: "/service/country-coverage/totalByCountry",
    },
    COUNTRY_COVERAGE_TOTAL_BY_DESIGNATION: {
        PATH: "/service/country-coverage/totalByDesignation",
    },
    //stage failure log
    ALL_STAGE_FAILURE_LOG: {
        PATH: "/service/log-stage-failure",
    },
    ALL_STAGE_FAILURE_STEPS: {
        PATH: "/service/log-stage-steps",
    },
}

function _getUserToken() {
    const tokenString = AppLocalStorage.getLocalStorageFor(AppLocalStorage.LOCAL_STORAGE_KEYS.SESSION_TOKEN)
    if (Utils.isJson(tokenString)) {
        const tokenJson = JSON.parse(tokenString)
        
        return tokenJson && tokenJson.token ? tokenJson.token : ""
    }

    return ""
}

export function AxiosGET(url, successCallBack, failureCallback) {
    axios({
        method: "get",
        baseURL: SERVER.PROTOCOL + SERVER.DOMAIN + ":" + SERVER.PORT,
        url: url,
        headers: {
            "Authorization": API.BASE_KEY,
            "X-Auth-Token": _getUserToken(),
            "Content-Type": "application/json",
            "Accept": "*/*",
        }
    })
    .then(({ data }) => {
        successCallBack(data)
    })
    .catch((error) => {
        console.log({error})
        if (error && error.response && error.response.status === 401) {
            window.location="/logout"
        } else {
            failureCallback ? failureCallback(error) : successCallBack(error)
        }
    });
}

export function AxiosPUT(url, putParams, successCallBack, failureCallback) {
    axios({
        method: "put",
        baseURL: SERVER.PROTOCOL + SERVER.DOMAIN + ":" + SERVER.PORT,
        url: url,
        data: putParams,
        headers: {
            "Authorization": API.BASE_KEY,
            "X-Auth-Token": _getUserToken(),
            "Content-Type": "application/json",
            "Accept": "*/*",
        }
    })
    .then(( data ) => {
        successCallBack(data)
    })
    .catch((error) => {
        console.log({error})
        failureCallback ? failureCallback(error) : successCallBack(error)
    });
}

export function AxiosPOST(url, postParams, successCallBack, failureCallback) {
    axios({
        method: "post",
        baseURL: SERVER.PROTOCOL + SERVER.DOMAIN + ":" + SERVER.PORT,
        url: url,
        data: postParams,
        headers: {
            "Authorization": API.BASE_KEY,
            "X-Auth-Token": _getUserToken(),
            "Content-Type": "application/json",
            "Accept": "*/*",
        }
    })
    .then(( data ) => {
        successCallBack(data)
    })
    .catch((error) => {
        console.log({error})
        failureCallback ? failureCallback(error) : successCallBack(error)
    });
}

export function AxiosDELETE(url, successCallBack, failureCallback) {
    axios({
        method: "delete",
        baseURL: SERVER.PROTOCOL + SERVER.DOMAIN + ":" + SERVER.PORT,
        url: url,
        headers: {
            "Authorization": API.BASE_KEY,
            "X-Auth-Token": _getUserToken(),
            "Content-Type": "application/json",
            "Accept": "*/*",
        }
    })
    .then(( data ) => {
        successCallBack(data)
    })
    .catch((error) => {
        console.log({error})
        failureCallback ? failureCallback(error) : successCallBack(error)
    });
}