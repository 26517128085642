import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
  ButtonGroup,
  Button,
  Tooltip
 } from "shards-react";
import { CSVLink } from "react-csv";
import PageTitle from "../../components/common/PageTitle";
import ReqDualNumbersTableCard from "../../components/table/ReqDualNumbersTableCard";
import ReqSuccessNewUsersTableCard from "../../components/table/ReqSuccessNewUsersTableCard";
import { AxiosGET, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const StatsReqDualNumbers = (props) => {
  const [allReqSuccessDualNumbers, setAllReqSuccessDualNumbers] = useState();
  const [allReqFailDualNumbers, setAllReqFailDualNumbers] = useState();
  const [allReqSuccessNewUsers, setAllReqSuccessNewUsers] = useState();
  const [reqSuccessDataToExport, setReqSuccessDataToExport] = useState();
  const [reqFailDataToExport, setReqFailDataToExport] = useState();
  const [exportSuccessTooltipOpen, setExportSuccessTooltipOpen] = useState(false)
  const [exportFailTooltipOpen, setExportFailTooltipOpen] = useState(false)
  const myNumberSuccessColumnID = "rsdnMyNumber"
  const reqTimeSuccessColumnID = "rsdnReqTime"
  const ReqSuccessColumns = React.useMemo(
    () => [
      {
        Header: 'Requests',
        columns: [
          {
            Header: 'My Number',
            accessor: myNumberSuccessColumnID,
          },
          {
            Header: 'rsdnMyNumberForSearchPlus',
            accessor: 'rsdnMyNumberForSearchPlus',
          },
          {
            Header: 'rsdnMyNumberForSearch00',
            accessor: 'rsdnMyNumberForSearch00',
          },
          {
            Header: 'rsdnMyNumberForSearchFormatted',
            accessor: 'rsdnMyNumberForSearchFormatted',
          },
          {
            Header: 'Date and Time',
            accessor: reqTimeSuccessColumnID,
          },
        ],
      }
    ],
    []
  )
  const myNumberFailColumnID = "rsdnMyNumber"
  const reasonFailColumnID = "rsdnReason"
  const reqTimeFailColumnID = "rsdnReqTime"
  const ReqFailColumns = React.useMemo(
    () => [
      {
        Header: 'Requests',
        columns: [
          {
            Header: 'My Number',
            accessor: myNumberFailColumnID,
          },
          {
            Header: 'rsdnMyNumberForSearchPlus',
            accessor: 'rsdnMyNumberForSearchPlus',
          },
          {
            Header: 'rsdnMyNumberForSearch00',
            accessor: 'rsdnMyNumberForSearch00',
          },
          {
            Header: 'rsdnMyNumberForSearchFormatted',
            accessor: 'rsdnMyNumberForSearchFormatted',
          },
          {
            Header: 'Failure Reason',
            accessor: reasonFailColumnID,
          },
          {
            Header: 'Date and Time',
            accessor: reqTimeFailColumnID,
          },
        ],
      }
    ],
    []
  )

  const myNumberNewUsersColumnID = "myNumber"
  const firstRequestAtNewUsersColumnID = "firstRequestAt"
  const totalNewUsersColumnID = "total"
  const ReqSuccessNewUsersColumns = React.useMemo(
    () => [
      {
        Header: 'My Numbers',
        columns: [
          {
            Header: 'My Number',
            accessor: myNumberNewUsersColumnID,
          },
          {
            Header: 'myNumberForSearchPlus',
            accessor: 'myNumberForSearchPlus',
          },
          {
            Header: 'myNumberForSearch00',
            accessor: 'myNumberForSearch00',
          },
          {
            Header: 'myNumberForSearchFormatted',
            accessor: 'myNumberForSearchFormatted',
          },
          {
            Header: 'First Request',
            accessor: firstRequestAtNewUsersColumnID,
          },
          {
            Header: 'Total',
            accessor: totalNewUsersColumnID,
          },
        ],
      }
    ],
    []
  )

  useEffect(() => {
    AxiosGET(API.STATS_REQ_SUCCESS_DUAL_NUMBERS.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          rsdnMyNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.rsdnMyNumber),
          rsdnMyNumberForSearch00: Utils.getNumberWithPrefix00(d.rsdnMyNumber),
          rsdnMyNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.rsdnMyNumber),
        })

        return d
      })
      setAllReqSuccessDualNumbers(data)
    }, (e) => toast.error("It was not possible to get the requests for success"))
    AxiosGET(API.STATS_REQ_FAIL_DUAL_NUMBERS.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          rsdnMyNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.rsdnMyNumber),
          rsdnMyNumberForSearch00: Utils.getNumberWithPrefix00(d.rsdnMyNumber),
          rsdnMyNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.rsdnMyNumber),
        })

        return d
      })
      setAllReqFailDualNumbers(data)
    }, (e) => toast.error("It was not possible to get the requests for failure"))
    AxiosGET(API.STATS_REQ_SUCCESS_NEW_USERS.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          myNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.myNumber),
          myNumberForSearch00: Utils.getNumberWithPrefix00(d.myNumber),
          myNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.myNumber),
        })

        return d
      })
      setAllReqSuccessNewUsers(data)
    }, (e) => toast.error("It was not possible to get the new users last week"))
  }, []);

  useEffect(() => {
    var reqSuccessDataToExportArr = []
    reqSuccessDataToExportArr.push(["My Number", "Request Time"])
    if (allReqSuccessDualNumbers) {
      allReqSuccessDualNumbers.forEach(item => {
        reqSuccessDataToExportArr.push([item.rsdnMyNumber, item.rsdnReqTime])
      })
    }

    setReqSuccessDataToExport(reqSuccessDataToExportArr)
  }, [allReqSuccessDualNumbers]);

  useEffect(() => {
    var reqFailDataToExportArr = []
    reqFailDataToExportArr.push(["My Number", "Failure Reason", "Request Time"])
    if (allReqFailDualNumbers) {
      allReqFailDualNumbers.forEach(item => {
        reqFailDataToExportArr.push([item.rsdnMyNumber, item.rsdnReason, item.rsdnReqTime])
      })
    }

    setReqFailDataToExport(reqFailDataToExportArr)
  }, [allReqFailDualNumbers]);

  const mainSuccessCardActions = (
    <Col lg="6" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm">
        <CSVLink
          data={reqSuccessDataToExport}
          filename={"DualServiceSuccessRequests.csv"}
        >
          <Button id="exportSuccessBtn" theme="secondary" onClick={() => {}}>
            <span className="text-white">
              <i className="material-icons">file_upload</i>
            </span>{" "}
            Export
          </Button>
          <Tooltip
            placement="bottom"
            open={exportSuccessTooltipOpen}
            target={"#exportSuccessBtn"}
            toggle={() => setExportSuccessTooltipOpen(!exportSuccessTooltipOpen)}
          >
            Export successful requests to a .csv file
          </Tooltip>
        </CSVLink>
      </ButtonGroup>
    </Col>
  )

  const mainFailCardActions = (
    <Col lg="6" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm">
        <CSVLink
          data={reqFailDataToExport}
          filename={"DualServiceFailRequests.csv"}
        >
          <Button id="exportFailBtn" theme="secondary" onClick={() => {}}>
            <span className="text-white">
              <i className="material-icons">file_upload</i>
            </span>{" "}
            Export
          </Button>
          <Tooltip
            placement="bottom"
            open={exportFailTooltipOpen}
            target={"#exportFailBtn"}
            toggle={() => setExportFailTooltipOpen(!exportFailTooltipOpen)}
          >
            Export failure requests to a .csv file
          </Tooltip>
        </CSVLink>
      </ButtonGroup>
    </Col>
  )

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Requests for dual numbers" subtitle="Dual SIM" className="text-sm-left mb-3" />
      </Row>
      
      <Fade>
        <Row>
            <Col lg="6" className="mb-4">
              {
                allReqSuccessDualNumbers &&
                <ReqDualNumbersTableCard myNumberColumnID={myNumberSuccessColumnID} reqTimeColumnID={reqTimeSuccessColumnID} columns={ReqSuccessColumns} data={allReqSuccessDualNumbers} title="Successful Requests" mainCardActions={mainSuccessCardActions} titleIcon={(<span className="text-success"><i className="material-icons">check</i></span>)} />
              }
            </Col>
            <Col lg="6" className="mb-4">
              {
                allReqFailDualNumbers &&
                <ReqDualNumbersTableCard myNumberColumnID={myNumberFailColumnID} reasonFailColumnID={reasonFailColumnID} reqTimeColumnID={reqTimeFailColumnID} columns={ReqFailColumns} data={allReqFailDualNumbers} title="Failed Requests" mainCardActions={mainFailCardActions} titleIcon={(<span className="text-warning"><i className="material-icons">error</i></span>)} />
              }
            </Col>
        </Row>
        <Row>
            <Col lg="5" className="mb-4">
              {
                allReqSuccessNewUsers &&
                <ReqSuccessNewUsersTableCard myNumberColumnID={myNumberNewUsersColumnID} firstRequestAtNewUsersColumnID={firstRequestAtNewUsersColumnID} totalNewUsersColumnID={totalNewUsersColumnID} columns={ReqSuccessNewUsersColumns} data={allReqSuccessNewUsers} title="New users on the last 30 days" />
              }
            </Col>
        </Row>
      </Fade>
  
    </Container>
  )
}

export default StatsReqDualNumbers;
