import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
  ButtonGroup,
  Button,
 } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import CallThroughNumbersTableCard from "../../components/table/CallThroughNumbersTableCard";
import { AxiosGET, API } from "../../utils/BackendAPI";
import { useAuth } from "../../context/auth";
import * as Utils from "../../utils/Utils";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const CallThroughNumbers = (props) => {
  const [allCallThroughNumbers, setAllCallThroughNumbers] = useState();
  const { setServiceSuccess } = useAuth();
  const opsColumnName = "Actions"
  const numberColumnID = "ctnNumber"
  const CallThroughNumbersColumns = React.useMemo(
    () => [
      {
        Header: 'Callthrough Numbers',
        columns: [
          {
            Header: 'Number',
            accessor: numberColumnID,
          },
          {
            Header: 'ctnNumberForSearchPlus',
            accessor: 'ctnNumberForSearchPlus',
          },
          {
            Header: 'ctnNumberForSearch00',
            accessor: 'ctnNumberForSearch00',
          },
          {
            Header: 'ctnNumberForSearchFormatted',
            accessor: 'ctnNumberForSearchFormatted',
          },
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            // accessor: 'ctnActive',
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )

  const mainCardActions = (
    <Col lg="4" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm">
        <Button theme="primary" onClick={() => props.history.push("/new-call-through-number")}>
          <span className="text-white">
            <i className="material-icons">add</i>
          </span>{" "}
          Add Number
        </Button>
      </ButtonGroup>
    </Col>
  )

  useEffect(() => {
    fnRenderTable()
  }, []);

  function fnRenderTable(newActiveNumber) {
    AxiosGET(API.ALL_CALL_THROUGH_NUMBERS.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          ctnNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.ctnNumber),
          ctnNumberForSearch00: Utils.getNumberWithPrefix00(d.ctnNumber),
          ctnNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.ctnNumber),
        })

        return d
      })
      setAllCallThroughNumbers(data)
      if (newActiveNumber) {
        setServiceSuccess("The callthrough number (" + newActiveNumber + ") is now the active number!")
      }
    }, 
    (e) => toast.error("It was not possible to load the callthrough numbers"))
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Callthrough numbers" subtitle="Dual SIM" className="text-sm-left mb-3" />
      </Row>
  
      <Fade>
        {
          allCallThroughNumbers &&
          <Row>
            <Col lg="12" className="mb-4">
              <CallThroughNumbersTableCard columns={CallThroughNumbersColumns} data={allCallThroughNumbers} title="Callthrough numbers" opsColumnName="Actions" numberColumnID={numberColumnID} nav={props.history} fnRenderTable={fnRenderTable} mainCardActions={mainCardActions} />
            </Col>
          </Row>
        }
      </Fade>
  
    </Container>
  )
}

export default CallThroughNumbers;
